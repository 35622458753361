import React, { useEffect, useState } from "react"
import API, { graphqlOperation } from "@aws-amplify/api"
import { I18n } from "aws-amplify"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import Typography from "@material-ui/core/Typography"
import { getFormattedDateTime } from "../../utils/dateUtil"
import { onPostConversationMessageByConversation } from "../../graphql/subscriptions"

const Conversation = ({ conversation, onClick }) => {
  const [unreadMessages, setUnreadMessages] = useState(
    conversation.customerUnreadMessages
  )
  const [lastCustomerMessageDate, setCustomerLastMessageDate] = useState(
    conversation.lastMessageDate
  )
  const getLanguageName = () => {
    const languageCode = `language_${conversation.customerLocale}`
    return I18n.get(languageCode)
  }
  let subscribed = true

  useEffect(() => {
    let messagesObserver = subscribeToNewMessage()
    return () => {
      subscribed = false
      messagesObserver.unsubscribe()
    }
  }, [])

  const subscribeToNewMessage = () => {
    const args = {
      conversationId: conversation.createDate,
      sender: "CUSTOMER",
    }
    return API.graphql(
      graphqlOperation(onPostConversationMessageByConversation, args)
    ).subscribe({
      next: (evt) => {
        console.log(evt)
        if (subscribed) {
          const message = evt.value.data.onPostConversationMessageByConversation
          if (conversation) {
            setCustomerLastMessageDate(message.sentDate)
            increaseUnreadMessagesCount()
          }
        }
      },
      error: (error) => {
        console.log(error)
      },
    })
  }
  const increaseUnreadMessagesCount = () => {
    setUnreadMessages(unreadMessages + 1)
  }

  return (
    <TableRow onClick={(e) => onClick(conversation)}>
      <TableCell style={{ width: 100 }}>
        <Typography variant="body2">
          {getFormattedDateTime(conversation.createDate)}
        </Typography>
      </TableCell>
      <TableCell style={{ width: 100 }}>
        <Typography variant="body2">{getLanguageName()}</Typography>
      </TableCell>
      <TableCell style={{ width: 100 }}>
        <Typography variant="body2">{conversation.source}</Typography>
      </TableCell>
      <TableCell style={{ width: 50 }}>
        <Typography variant="body2">
          {conversation.conversationStatus}
        </Typography>
      </TableCell>
      <TableCell style={{ width: 50 }}>
        <Typography variant="body2" color="error">
          {unreadMessages}
        </Typography>
      </TableCell>
      <TableCell key="employeeName" style={{ width: 100 }}>
        <Typography variant="body2">
          {getFormattedDateTime(lastCustomerMessageDate)}
        </Typography>
      </TableCell>
    </TableRow>
  )
}

export default Conversation
