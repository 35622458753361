import React, { Fragment, useEffect, useState } from "react"
import { I18n } from "aws-amplify"
import API, { graphqlOperation } from "@aws-amplify/api"
import { makeStyles } from "@material-ui/core/styles"
import {
  listConversationsByEmployee,
  listEmployees,
} from "../../graphql/queries"

import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@material-ui/core"
import ConversationsList from "./ConversationsList"
import ConversationOverview from "./ConversationOverview"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    marginTop: 30,
    width: 200,
  },
}))
const EmployeeConversations = (props) => {
  const classes = useStyles()
  const [selectedEmployeeUsername, setSelectedEmployeeUsername] = useState("")
  const [employees, setEmployees] = useState([])
  const [assignedConversations, setAssignedConversations] = useState([])
  const [openConversations, setOpenConversations] = useState([])
  const [closedConversations, setClosedConversations] = useState([])
  const [selectedConversation, setSelectedConversation] = useState()

  let subscribed = true
  useEffect(() => {
    loadEmployees()
    return () => {
      subscribed = false
    }
  }, [])
  useEffect(() => {
    if (selectedEmployeeUsername) loadConversations(selectedEmployeeUsername)
  }, [selectedEmployeeUsername])
  const loadEmployees = () => {
    API.graphql(graphqlOperation(listEmployees))
      .then((response) => {
        const employees = response.data.listEmployees.items

        if (subscribed) setEmployees(employees)
      })
      .catch((error) => {
        console.log(error)
      })
  }
  const loadConversations = (employeeId) => {
    const args = {
      employeeId,
    }
    API.graphql(graphqlOperation(listConversationsByEmployee, args))
      .then((response) => {
        const allConversations = response.data.listConversationsByEmployee
        let assigned = []
        let open = []
        let closed = []
        for (const conversation of allConversations) {
          switch (conversation.conversationStatus) {
            case "ASSIGNED":
              assigned.push(conversation)
              break

            case "OPEN":
              open.push(conversation)
              break
            case "CLOSED":
              closed.push(conversation)
              break
          }
        }

        if (subscribed) {
          setAssignedConversations(assigned)
          setOpenConversations(open)
          setClosedConversations(closed)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleOpenConversation = (conversation) => {
    setSelectedConversation(conversation)
  }
  const handleClostDialog = () => {
    setSelectedConversation()
  }
  return (
    <Fragment>
      <Paper className={classes.root}>
        <FormControl className={classes.formControl}>
          <InputLabel shrink htmlFor="employee-select">
            {I18n.get("label_select_employee")}
          </InputLabel>
          <Select
            onChange={(e) => setSelectedEmployeeUsername(e.target.value)}
            value={selectedEmployeeUsername}
            displayEmpty
            className={classes.selectEmpty}
            inputProps={{
              id: "employee-select",
            }}
          >
            {employees.map((employee, index) => (
              <MenuItem key={index} value={employee.username}>
                {employee.employeeName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Paper>
      {selectedEmployeeUsername && (
        <Paper className={classes.root}>
          <Typography variant="body2" style={{ padding: 10 }}>
            {I18n.get("label_assigned_conversations")}
          </Typography>
          <ConversationsList
            conversations={assignedConversations}
            onClick={handleOpenConversation}
          />
          <Typography variant="body2" style={{ padding: 10 }}>
            {I18n.get("label_open_conversations")}
          </Typography>
          <ConversationsList
            conversations={openConversations}
            onClick={handleOpenConversation}
          />
          <Typography variant="body2" style={{ padding: 10 }}>
            {I18n.get("label_closed_conversations")}
          </Typography>
          <ConversationsList
            conversations={closedConversations}
            onClick={handleOpenConversation}
          />
        </Paper>
      )}
      <Dialog
        open={selectedConversation != null}
        onClose={handleClostDialog}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          {I18n.get("label_assign_conversation")}
        </DialogTitle>
        <DialogContent>
          <ConversationOverview
            conversation={selectedConversation}
            employees={employees}
          ></ConversationOverview>
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}

export default EmployeeConversations
