import { API, graphqlOperation, I18n } from "aws-amplify"
import React, { useEffect, useState, useContext } from "react"
import GlobalContext from "../../context/global-context"
import Typography from "@material-ui/core/Typography"
import Paper from "@material-ui/core/Paper"
import { getOpenOrderItems } from "../../graphql/queries"
import BreadOrdersItem from "./BreadOrdersItem"

const countryCode = "de"
const warehouseId = "4331b036-b379-4cb6-86c2-59e45f34d2e1"
const BreadOrdersPage = () => {
  const { currentUser } = useContext(GlobalContext)
  const [orderItems, setOrderItems] = useState([])
  const [queried, setQueried] = useState(false)
  useEffect(() => {
    loadOpenOrderItems()
  }, [])
  const loadOpenOrderItems = () => {
    const params = {
      countryCode,
      warehouseId,
      skus: ["106002002", "106002003", "106002005", "106002006", "106002004"],
      locale: currentUser.locale,
    }
    API.graphql(graphqlOperation(getOpenOrderItems, params)).then(
      (response) => {
        console.log(response.data.getOpenOrderItems)
        setOrderItems(response.data.getOpenOrderItems)
        setQueried(true)
      }
    )
  }

  return (
    <Paper>
      {queried && orderItems.length === 0 && (
        <Typography>{I18n.get("label_not_exists")}</Typography>
      )}
      {orderItems.map((item, index) => (
        <div key={index}>
          <BreadOrdersItem item={item} />
          <hr />
        </div>
      ))}
    </Paper>
  )
}

export default BreadOrdersPage
