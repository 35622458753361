import React, { useState, useContext, useEffect, Fragment } from "react"
import { I18n } from "aws-amplify"
import API, { graphqlOperation } from "@aws-amplify/api"
import { getTopSellers } from "../../graphql/queries"
import {
  Paper,
  Button,
  Grid,
  CircularProgress,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import GlobalContext from "../../context/global-context"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"

const useStyle = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    "& div": {
      marginBottom: theme.spacing(1),
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  tableContainer: {
    width: "100%",
    maxHeight: 500,
  },
}))

const ItemsSalesPage = () => {
  const { currentUser } = useContext(GlobalContext)
  const classes = useStyle()
  const [itemsSales, setItemsSales] = useState()
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [parent, setParent] = useState("")
  const [salesBeingLoaded, setSalesBeingLoaded] = useState(false)
  const [totalCount, setTotalCount] = useState(0)

  const countryCode = "de"
  const warehouseId = "4331b036-b379-4cb6-86c2-59e45f34d2e1"

  useEffect(() => {
    if (itemsSales) {
      let count = 0
      for (const sale of itemsSales) {
        count += sale.quantity
      }
      setTotalCount(count)
    }
  }, [itemsSales])

  const startDateChanged = (e) => {
    setStartDate(e.target.value)
  }
  const endDateChanged = (e) => {
    setEndDate(e.target.value)
  }

  const searchClicked = () => {
    setSalesBeingLoaded(true)
    loadSales()
  }

  const loadSales = async () => {
    let params = {
      countryCode,
      warehouseId,
      userLocale: currentUser.locale,
    }
    if (startDate) {
      params.startDate = startDate
    }
    if (endDate) {
      params.endDate = endDate
    }
    if (parent) params.parent = parent
    const response = await API.graphql(graphqlOperation(getTopSellers, params))
    setSalesBeingLoaded(false)
    setItemsSales(response.data.getTopSellers)
  }

  const getMargin = (price, cost) => {
    const margin = price - cost

    const absMargin = parseFloat(Math.abs(margin).toFixed(2))
    return (
      <Typography color={margin < 0 ? "error" : "secondary"}>
        {absMargin}
      </Typography>
    )
  }
  const getRate = (price, cost) => {
    const rate = ((price / cost - 1) * 100).toFixed(2)

    const absRate = parseFloat(Math.abs(rate).toFixed(2))
    return (
      <Typography color={rate < 0 ? "error" : "secondary"}>
        {absRate}
      </Typography>
    )
  }
  const exportToCsv = () => {
    let rows = [
      [
        "sku",
        "name",
        "quantity",
        "avg price",
        "avg cost",
        "avg margin",
        "rate %",
      ],
    ]
    itemsSales.map((item) => {
      const avgMargin = parseFloat((item.avgPrice - item.avgCost).toFixed(2))
      const rate = ((item.avgPrice / item.avgCost - 1) * 100).toFixed(2)
      rows.push([
        item.sku,
        item.name,
        item.quantity,
        item.avgPrice,
        item.avgCost,
        avgMargin,
        rate,
      ])
    })
    let csvContent =
      "data:text/csv;charset=utf-8," + rows.map((e) => e.join(";")).join("\n")
    let encodedUri = encodeURI(csvContent)
    window.open(encodedUri)
  }
  return (
    <React.Fragment>
      <Grid container justify="flex-start">
        <TextField
          id="start_date"
          label={I18n.get("label_from")}
          type="date"
          className={classes.textField}
          onChange={startDateChanged}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          id="end_date"
          label={I18n.get("label_to")}
          type="date"
          className={classes.textField}
          onChange={endDateChanged}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          id="category"
          label={I18n.get("label_category")}
          className={classes.textField}
          onChange={(e) => setParent(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Button
        className={classes.action}
        variant="contained"
        color="primary"
        onClick={searchClicked}
      >
        {I18n.get("action_search")}
      </Button>
      <TextField
        disabled
        label={I18n.get("label_count")}
        className={classes.textField}
        value={totalCount}
      />
      <Paper className={classes.container}>
        {itemsSales ? (
          <Fragment>
            <Button
              variant="contained"
              color="primary"
              onClick={exportToCsv}
              disabled={itemsSales.length === 0}
            >
              Export to CSV
            </Button>
            <TableContainer className={classes.tableContainer}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>{I18n.get("label_sku")}</TableCell>
                    <TableCell>{I18n.get("label_name")}</TableCell>
                    <TableCell>{I18n.get("label_quantity")}</TableCell>
                    <TableCell>{I18n.get("label_avg_price")}</TableCell>
                    <TableCell>{I18n.get("label_avg_cost")}</TableCell>
                    <TableCell>{I18n.get("label_avg_margin")}</TableCell>
                    <TableCell>%</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {itemsSales.map((sale, index) => (
                    <TableRow key={index}>
                      <TableCell>{sale.sku}</TableCell>
                      <TableCell>{sale.name}</TableCell>
                      <TableCell>{sale.quantity}</TableCell>
                      <TableCell>{sale.avgPrice}</TableCell>
                      <TableCell>{sale.avgCost}</TableCell>
                      <TableCell>
                        {getMargin(sale.avgPrice, sale.avgCost)}
                      </TableCell>
                      <TableCell>
                        {getRate(sale.avgPrice, sale.avgCost)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Fragment>
        ) : (
          salesBeingLoaded && <CircularProgress color="secondary" />
        )}
      </Paper>
    </React.Fragment>
  )
}
export default ItemsSalesPage
