import React, {
  useReducer,
  useEffect,
  useContext,
  useState,
  Fragment,
} from "react"
import { I18n } from "aws-amplify"
import GlobalContext from "../../context/global-context"
import API, { graphqlOperation } from "@aws-amplify/api"
import Paper from "@material-ui/core/Paper"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"

import { makeStyles } from "@material-ui/core/styles"
import conversationMessagesReducer from "./conversationMessagesReducer"
import Chat from "./Chat"
import {
  getConversation,
  getCustomerByNumber,
  getUnreadConversationMessages,
  listConversationMessages,
  listEmployees,
} from "../../graphql/queries"
import { onPostConversationMessageByConversation } from "../../graphql/subscriptions"
import {
  postConversationMessage,
  assignConversation,
  closeConversation,
} from "../../graphql/mutations"
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5),
  },
  chat: {
    width: 400,
  },
  orders: {
    width: "70%",
  },
  grid: {
    margin: theme.spacing(1),
  },
  closeConversationtButton: {
    width: "100%",
  },
  input: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  formControl: {
    marginTop: 30,
    width: 200,
  },
}))

const OpenConversationPage = (props) => {
  const classes = useStyles()
  const { currentUser } = useContext(GlobalContext)
  const [{ messages }, dispatch] = useReducer(conversationMessagesReducer, {
    messages: [],
  })
  const [closingConversation, setClosingConversation] = useState(false)

  const [measures, setMeasures] = useState("")
  const [customer, setCustomer] = useState()
  const [selectedEmployeeUsername, setSelectedEmployeeUsername] = useState("")
  const [employees, setEmployees] = useState([])
  const [conversationReassigned, setConversationReassigned] = useState(false)
  const [conversation, setConversation] = useState()

  const {
    match: { params },
  } = props
  const countryCode = params.countryCode
  const customerNumber = params.customerNumber
  const conversationId = params.conversationId

  let loaded = false
  let subscribed = true
  let messagesOverserver
  let newMessagesPicker

  // useEffect(() => {
  //   loadConversation()
  //   loadEmployees()
  //   return () => {
  //     if (messagesOverserver) messagesOverserver.unsubscribe()
  //     subscribed = false
  //   }
  // }, [])
  useEffect(() => {
    loadConversation()
    loadEmployees()
    console.log("start interval")
    newMessagesPicker = setInterval(loadNewMessages, 2000)
    return () => {
      console.log("clear interval")
      clearInterval(newMessagesPicker)
      subscribed = false
    }
  }, [])

  // useEffect(() => {
  //   if (conversation) {
  //     if (!loaded) {
  //       loadMessages()
  //       subscribeToMessages()

  //       loadEmployees()
  //       loaded = true
  //     }
  //   }
  // }, [conversation])

  const loadConversation = () => {
    const args = {
      conversationId,
      customerNumber,
      countryCode,
    }
    API.graphql(graphqlOperation(getConversation, args))
      .then((response) => {
        if (subscribed) {
          setConversation(response.data.getConversation)
          loadMessages()
          loadCustomer()
        }
      })
      .catch((error) => console.log(error))
  }

  const loadMessages = () => {
    const args = {
      conversationId: conversationId,
      customerNumber: customerNumber,
      countryCode: countryCode,
    }
    API.graphql(graphqlOperation(listConversationMessages, args))
      .then((response) => {
        const m = response.data.listConversationMessages
        if (subscribed && m) {
          dispatch({
            type: "LOAD_MMESSAGES",
            messages: m,
          })
        }
        subscribeToMessages()
      })
      .catch((error) => console.log(error))
  }

  const subscribeToMessages = () => {
    // const args = {
    //   convsationId: conversationId,
    //   sender: "CUSTOMER",
    // }
    // console.log(args)
    // messagesOverserver = API.graphql(
    //   graphqlOperation(onPostConversationMessageByConversation, args)
    // ).subscribe({
    //   next: (evt) => {
    //     console.log(args)
    //     console.log(evt)
    //     const message = evt.value.data.onPostConversationMessageByConversation
    //     if (message && message.conversationId === conversationId && subscribed)
    //       dispatch({ type: "ADD_NEW_MESSAGE", message: message })
    //   },
    //   error: (error) => {
    //     console.log(error)
    //   },
    // })
  }
  const loadCustomer = () => {
    API.graphql(
      graphqlOperation(getCustomerByNumber, {
        customerNumber: customerNumber,
      })
    )
      .then((response) => {
        if (subscribed) setCustomer(response.data.getCustomerByNumber)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const loadEmployees = () => {
    API.graphql(graphqlOperation(listEmployees))
      .then((response) => {
        const employees = response.data.listEmployees.items

        if (subscribed) setEmployees(employees)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const loadNewMessages = () => {
    console.log("To get new messages")
    const args = {
      countryCode: countryCode,
      conversationId: conversationId,
      customerNumber: customerNumber,
      sender: "CUSTOMER",
    }
    API.graphql(graphqlOperation(getUnreadConversationMessages, args))
      .then((response) => {
        const messages = response.data.getUnreadConversationMessages

        if (subscribed) {
          dispatch({ type: "ADD_NEW_MESSAGES", messages })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleSendMessage = (message) => {
    const args = {
      conversationId: conversation.createDate,
      countryCode: conversation.countryCode,
      customerNumber: conversation.customerNumber,
      sender: "ALADDIN",
      type: "TEXT",
      message: message,
      employeeId: currentUser.username,
      employeeName: currentUser.employeeName,
    }
    API.graphql(graphqlOperation(postConversationMessage, args))
      .then((response) => {
        if (subscribed)
          dispatch({
            type: "ADD_NEW_MESSAGE",
            message: response.data.postConversationMessage,
          })
      })
      .catch((error) => console.log(error))
  }

  const doCloseConversation = async (measures) => {
    const args = {
      conversationId: conversation.createDate,
      countryCode: conversation.countryCode,
      customerNumber: conversation.customerNumber,
      measures,
    }
    try {
      await API.graphql(graphqlOperation(closeConversation, args))
      //props.history.push(`/conversations`)
      props.history.goBack()
    } catch (error) {
      console.log(error)
    }
  }

  const handleCloseConversation = () => {
    if (!closingConversation) {
      setClosingConversation(true)
    } else {
      if (measures !== "") {
        doCloseConversation(measures)
      }
    }
  }
  const reAssignConversation = () => {
    if (selectedEmployeeUsername) {
      let selectedEmployee
      for (const employee of employees) {
        if (employee.username === selectedEmployeeUsername) {
          selectedEmployee = employee
        }
      }
      if (selectedEmployee) {
        const args = {
          conversationId: conversation.createDate,
          customerNumber: conversation.customerNumber,
          countryCode: conversation.countryCode,
          employeeId: selectedEmployee.username,
          employeeName: selectedEmployee.employeeName,
        }
        API.graphql(graphqlOperation(assignConversation, args))
          .then((response) => {
            setConversationReassigned(true)
          })
          .catch((error) => {
            console.log(error)
          })
      }
    }
  }

  return !conversationReassigned ? (
    <Paper className={classes.root}>
      <div>
        {!customer ? (
          <p>{I18n.get("label_customer_not_registered")}</p>
        ) : (
          <p>
            <span>
              <a
                href={`/customerDetails/${conversation.customerNumber}`}
                target="_blank"
              >
                {I18n.get("message_customer_details_click_here")}
              </a>
            </span>
          </p>
        )}
      </div>
      {conversation && conversation.conversationStatus !== "CLOSED" && (
        <Fragment>
          <FormControl className={classes.formControl}>
            <InputLabel shrink htmlFor="employee-select">
              {I18n.get("label_select_employee")}
            </InputLabel>
            <Select
              onChange={(e) => setSelectedEmployeeUsername(e.target.value)}
              value={selectedEmployeeUsername}
              displayEmpty
              className={classes.selectEmpty}
              inputProps={{
                id: "employee-select",
              }}
            >
              {employees.map((employee, index) => (
                <MenuItem key={index} value={employee.username}>
                  {employee.employeeName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            className={classes.formControl}
            variant="contained"
            onClick={reAssignConversation}
            color="primary"
          >
            {I18n.get("action_assign_conversation")}
          </Button>
        </Fragment>
      )}

      <Grid
        className={classes.grid}
        container
        spacing={3}
        justify="space-between"
      >
        <Grid item className={classes.chat}>
          {closingConversation && (
            <TextField
              variant="outlined"
              className={classes.input}
              value={measures}
              placeholder={I18n.get("label_ticket_mesurement")}
              onChange={(e) => setMeasures(e.target.value)}
              multiline
              rows="6"
            />
          )}
          {conversation && conversation.conversationStatus !== "CLOSED" && (
            <Button
              variant="contained"
              className={classes.closeConversationButton}
              onClick={handleCloseConversation}
              color="primary"
            >
              {I18n.get("action_close_conversation")}
            </Button>
          )}

          {closingConversation && (
            <Button
              variant="outlined"
              className={classes.closeConversationButton}
              onClick={(e) => setClosingConversation(false)}
              color="primary"
            >
              {I18n.get("action_cancel")}
            </Button>
          )}
          {conversation && conversation.conversationStatus === "CLOSED" && (
            <div>
              <p>{I18n.get("message_conversation_closed_with_measures")}</p>
              <p>{conversation.measures}</p>
            </div>
          )}
          {!closingConversation && conversation && (
            <Chat
              messages={messages}
              sendMessageHandler={handleSendMessage}
              conversationStatus={conversation.conversationStatus}
            />
          )}
        </Grid>
      </Grid>
    </Paper>
  ) : (
    <Paper>
      <p>{I18n.get("message_conversation_reassigned")}</p>
    </Paper>
  )
}

export default OpenConversationPage
