import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core"
import { API, graphqlOperation, I18n } from "aws-amplify"
import {
  searchCustomers,
  getCustomerByNumber,
  getOrder,
} from "../../graphql/queries"
const useStyles = makeStyles({
  container: {
    width: "70%",
  },
  tableWrapper: {
    height: 600,
    overflow: "auto",
  },
  customerRow: {
    cursor: "pointer",
  },
})

const CustomerSearchPage = (props) => {
  const classes = useStyles()
  const [customerNumber, setCustomerNumber] = useState("")
  const [orderNumber, setOrderNumber] = useState("")
  const [customers, setCustomers] = useState([])
  const [email, setEmail] = useState("")
  const [mobile, setMobile] = useState("")
  const [name, setName] = useState("")
  const [zipCode, setZipCode] = useState("")
  const [formError, setFormError] = useState()
  const [beingUpdated, setBeingUpdated] = useState(false)

  const findCustomer = async () => {
    setFormError()
    if (
      customerNumber === "" &&
      orderNumber === "" &&
      mobile === "" &&
      email === "" &&
      name === "" &&
      zipCode === ""
    ) {
      return
    }
    try {
      if (customerNumber !== "") {
        await findByCustomerNumber(customerNumber)
        return
      }
      if (orderNumber !== "") {
        await findByOrderNumber()
        return
      }
      if (email !== "") {
        await findByEmail()
        return
      }
      if (mobile !== "") {
        await findByMobile()
        return
      }
      if (name !== "") {
        await findByName()
        return
      }
      if (zipCode !== "") {
        await findByZipCode()
        return
      }
    } catch (err) {
      console.log(err)
    }
  }
  const findByCustomerNumber = async (number) => {
    setBeingUpdated(true)
    const params = {
      customerNumber: number,
    }
    const response = await API.graphql(
      graphqlOperation(getCustomerByNumber, params)
    )
    setBeingUpdated(false)
    const fetchedCustomer = response.data.getCustomerByNumber
    if (fetchedCustomer) {
      setCustomers([fetchedCustomer])
      return
    } else {
      setFormError(I18n.get("error_customer_not_exists"))
      return
    }
  }
  const findByOrderNumber = async () => {
    setBeingUpdated(true)
    const orderParams = {
      orderNumber: orderNumber.toUpperCase(),
    }
    const orderResponse = await API.graphql(
      graphqlOperation(getOrder, orderParams)
    )
    setBeingUpdated(false)
    const order = orderResponse.data.getOrder
    if (!order) {
      setFormError(I18n.get("error_order_not_exists"))
      return
    }
    await findByCustomerNumber(order.customerNumber)
  }
  const findByEmail = async () => {
    const e = email.trim().toLowerCase()
    const filter = {
      email: {
        contains: e,
      },
    }
    doSearchCustomers(filter)
  }
  const findByMobile = async () => {
    let m = mobile.trim()
    while (m.startsWith("0")) {
      m = m.substring(1)
    }
    const filter = {
      mobileNumber: {
        contains: m,
      },
    }
    doSearchCustomers(filter)
  }
  const findByName = async () => {
    const filter = {
      billingAddress: {
        contains: name,
      },
    }
    doSearchCustomers(filter)
  }
  const findByZipCode = async () => {
    const filter = {
      billingAddress: {
        contains: `#${zipCode}`,
      },
    }
    doSearchCustomers(filter)
  }
  const doSearchCustomers = async (filter) => {
    setBeingUpdated(true)
    let params = {
      filter,
    }
    let allScanned = false
    let customers = []
    while (!allScanned) {
      const response = await API.graphql(
        graphqlOperation(searchCustomers, params)
      )
      const fetchedCustomers = response.data.searchCustomers.items
      customers.push(...fetchedCustomers)
      if (response.data.searchCustomers.nextToken) {
        params.nextToken = response.data.searchCustomers.nextToken
      } else {
        allScanned = true
      }
    }
    setBeingUpdated(false)
    setCustomers(customers)
  }
  const openDetails = (number) => {
    props.history.push(`/customerDetails/${number}`)
  }
  return (
    <div className={classes.container}>
      <Typography>{formError}</Typography>
      <Grid container spacing={1}>
        <Grid item md={6} lg={4}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="component-error">
              {I18n.get("label_customer_number")}
            </InputLabel>
            <OutlinedInput
              value={customerNumber}
              onChange={(e) => setCustomerNumber(e.target.value)}
              aria-describedby="component-error-text"
            />
          </FormControl>
        </Grid>
        <Grid item md={6} lg={4}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="component-error">
              {I18n.get("label_order_number")}
            </InputLabel>
            <OutlinedInput
              value={orderNumber}
              onChange={(e) => setOrderNumber(e.target.value)}
              aria-describedby="component-error-text"
            />
          </FormControl>
        </Grid>
        <Grid item md={6} lg={4}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="component-error">
              {I18n.get("label_email")}
            </InputLabel>
            <OutlinedInput
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              aria-describedby="component-error-text"
            />
          </FormControl>
        </Grid>
        <Grid item md={6} lg={4}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="component-error">
              {I18n.get("label_telephone")}
            </InputLabel>
            <OutlinedInput
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              aria-describedby="component-error-text"
            />
          </FormControl>
        </Grid>
        <Grid item md={6} lg={4}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="component-error">
              {I18n.get("label_name")}
            </InputLabel>
            <OutlinedInput
              value={name}
              onChange={(e) => setName(e.target.value)}
              aria-describedby="component-error-text"
            />
          </FormControl>
        </Grid>
        <Grid item md={6} lg={4}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="component-error">
              {I18n.get("label_zip_code")}
            </InputLabel>
            <OutlinedInput
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
              aria-describedby="component-error-text"
            />
          </FormControl>
        </Grid>
      </Grid>

      <Button variant="contained" onClick={findCustomer} color="primary">
        {I18n.get("action_search")}
      </Button>

      {beingUpdated && (
        <LinearProgress
          color="primary"
          style={{ height: 4, width: 300, marginTop: 10 }}
        />
      )}

      {!beingUpdated && customers.length === 0 && (
        <Typography>{I18n.get("message_not_found")}</Typography>
      )}
      <div className={classes.tableWrapper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell key="customerNumber" style={{ width: 100 }}>
                <Typography variant="subtitle1" color="textPrimary">
                  {I18n.get("label_customer_number")}
                </Typography>
              </TableCell>
              <TableCell key="customerNmae" style={{ width: 50 }}>
                <Typography variant="subtitle1" color="textPrimary">
                  {I18n.get("label_name")}
                </Typography>
              </TableCell>

              <TableCell key="customerUsername" style={{ width: 75 }}>
                <Typography variant="subtitle1" color="textPrimary">
                  {I18n.get("label_email")}
                </Typography>
              </TableCell>
              <TableCell key="pendingPayment" style={{ width: 75 }}>
                <Typography variant="subtitle1" color="textPrimary">
                  {I18n.get("label_telephone")}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customers.map((customer) => (
              <TableRow
                className={classes.customerRow}
                key={customer.customerNumber}
                onClick={(e) => openDetails(customer.customerNumber)}
              >
                <TableCell style={{ width: 100 }}>
                  <Typography variant="subtitle1" color="textPrimary">
                    {customer.customerNumber}
                  </Typography>
                </TableCell>
                <TableCell style={{ width: 50 }}>
                  {customer.billingAddress && (
                    <Typography variant="subtitle1" color="textPrimary">
                      {`${customer.billingAddress.firstName} ${customer.billingAddress.lastName}`}
                    </Typography>
                  )}
                </TableCell>

                <TableCell style={{ width: 75 }}>
                  <Typography variant="subtitle1" color="textPrimary">
                    {customer.email}
                  </Typography>
                </TableCell>
                <TableCell style={{ width: 75 }}>
                  <Typography variant="subtitle1" color="textPrimary">
                    {customer.mobileNumber}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

export default CustomerSearchPage
