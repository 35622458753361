import React, { useEffect, useState } from "react"
import { I18n } from "aws-amplify"
import List from "@material-ui/core/List"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import Message from "./Message"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.default,
    position: "relative",
    overflow: "auto",
    maxHeight: 600,
    marginTop: theme.spacing(1),
    direction: "rtl",
  },
  input: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  customerMessage: {
    width: "90%",
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(1),
    direction: "rtl",
  },
  aladdinMessage: {
    width: "90%",
    backgroundColor: theme.palette.primary.light,
    color: "white",
    margin: theme.spacing(1),
    direction: "rtl",
  },
  sendButton: {
    width: "100%",
  },
}))

const Chat = ({ conversationStatus, messages, sendMessageHandler }) => {
  const classes = useStyles()
  const [message, setMessage] = useState("")
  const [oldMessagesCount, setOldMessagesCount] = useState(0)

  const endOfMessages = React.createRef()

  useEffect(() => {
    if (messages.length > oldMessagesCount) {
      console.log("to scroll")
      endOfMessages.current.scrollIntoView({ behavior: "smooth" })
      setOldMessagesCount(messages.length)
    }
  }, [messages])

  const sendMessage = () => {
    if (message !== "") {
      sendMessageHandler(message)
      setMessage("")
    }
  }

  return (
    <React.Fragment>
      <List className={classes.root}>
        {messages.map((message, index) => (
          <Message key={index} message={message} />
        ))}
        <div style={{ float: "left" }} ref={endOfMessages}></div>
      </List>

      {conversationStatus !== "CLOSED" && (
        <TextField
          variant="outlined"
          className={classes.input}
          autoFocus={true}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          multiline
          rows="3"
        />
      )}

      {conversationStatus !== "CLOSED" && (
        <Button
          variant="contained"
          className={classes.sendButton}
          onClick={sendMessage}
          color="primary"
        >
          {I18n.get("action_send")}
        </Button>
      )}
    </React.Fragment>
  )
}

export default Chat
