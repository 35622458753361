import React, { useEffect } from "react"
import API, { graphqlOperation } from "@aws-amplify/api"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import { makeStyles } from "@material-ui/core/styles"
import { setConversationMessageRead } from "../../graphql/mutations"
import {
  getFormattedDateTime,
  getFormattedShortDate,
} from "../../utils/dateUtil"
const useStyles = makeStyles((theme) => ({
  customerMessage: {
    width: "90%",
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(1),
    direction: "rtl",
  },
  aladdinMessage: {
    width: "90%",
    backgroundColor: theme.palette.primary.light,
    color: "white",
    margin: theme.spacing(1),
    direction: "rtl",
  },
}))

const Message = (props) => {
  const classes = useStyles()

  useEffect(() => {
    if (props.message.sender === "CUSTOMER" && !props.message.read) {
      console.log(props.message)
      const args = {
        conversationId: props.message.conversationId,
        countryCode: props.message.countryCode,
        customerNumber: props.message.customerNumber,
        originalSender: "CUSTOMER",
        sendDate: props.message.sentDate,
      }
      API.graphql(graphqlOperation(setConversationMessageRead, args)).catch(
        (error) => console.log(error)
      )
    }
  }, [])
  return (
    <ListItem
      className={
        props.message.sender === "CUSTOMER"
          ? classes.customerMessage
          : classes.aladdinMessage
      }
    >
      <ListItemText
        secondary={getFormattedDateTime(props.message.sentDate)}
        primary={props.message.message}
      />
    </ListItem>
  )
}
export default Message
