const conversationsReducer = (state, action) => {
  switch (action.type) {
    case "LOAD_NEW_CONVERSATIONS":
      return { ...state, newConversations: action.conversations }
    case "LOAD_MY_CONVERSATIONS":
      let openConversations = []
      let newConversations = []
      for (const conversation of action.conversations) {
        if (conversation.conversationStatus === "OPEN") {
          openConversations.push(conversation)
        } else if (conversation.conversationStatus === "ASSIGNED") {
          newConversations.push(conversation)
        }
      }
      return {
        ...state,
        myNewConversations: newConversations,
        myOpenConversations: openConversations,
      }
    case "ADD_NEW_CONVERSATION":
      return {
        ...state,
        newConversations: [...state.newConversations, action.conversation],
      }
    case "ASSIGN_CONVERSATION":
      let remainConversation = state.newConversations.filter(
        (newConversation) => {
          if (action.conversation.createDate !== newConversation.createDate) {
            return newConversation
          }
        }
      )
      return {
        ...state,
        newConversations: remainConversation,
        myNewConversations: [...state.myNewConversations, action.conversation],
      }
    case "REMOVE_NEW_CONVERSATION": {
      let rc = state.newConversations.filter((newConversation) => {
        if (action.conversation.createDate !== newConversation.createDate) {
          return newConversation
        }
      })
      return {
        ...state,
        newConversations: rc,
      }
    }
    case "SET_CONVERSATION_OPEN":
      let remainConversations = state.myNewConversations.filter(
        (newConversation) => {
          if (action.conversation.createDate !== newConversation.createDate) {
            return newConversation
          }
        }
      )
      return {
        ...state,
        myNewConversations: remainConversations,
        myOpenConversations: [
          ...state.myOpenConversations,
          action.conversation,
        ],
      }
    case "CLOSE_CONVERSATION":
      let remainOpenConversations = state.myOpenConversations.filter(
        (openConversation) => {
          if (action.conversation.createDate !== openConversation.createDate) {
            return openConversation
          }
        }
      )
      return {
        ...state,
        myOpenConversations: remainOpenConversations,
      }
    default:
      return state
  }
}
export default conversationsReducer
