import React, { Fragment, useState } from "react"
import { API, graphqlOperation, I18n } from "aws-amplify"
import Typography from "@material-ui/core/Typography"
import {
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core"
import { correctQuantity } from "../../graphql/mutations"

const countryCode = "de"
const warehouseId = "4331b036-b379-4cb6-86c2-59e45f34d2e1"
const BreadOrdersItem = (props) => {
  const [quantity, setQuantity] = useState("")
  const [quantityError, setQuantityError] = useState(false)

  const setOrdersQuantityLimit = () => {
    console.log(props.item)
    const params = {
      countryCode,
      warehouseId,
      quantity,
      preOrderedProduct: true,
      barcode: props.item.barcode,
    }
    API.graphql(graphqlOperation(correctQuantity, params)).then((response) => {
      const result = response.data.correctQuantity
      if (!!result) {
        setQuantity("")
        setQuantityError(false)
      } else {
        setQuantityError(true)
      }
    })
  }
  return (
    <Fragment>
      <Typography>
        {I18n.get("label_name")}: {props.item.name}
      </Typography>
      <Typography>
        {I18n.get("label_quantity")}: {props.item.quantity}
      </Typography>
      <Typography>
        {I18n.get("label_orders")}: {props.item.orders}
      </Typography>
      {quantityError && (
        <Typography color="error">
          {I18n.get("error_negative_quantity")}
        </Typography>
      )}
      <div style={{ display: "flex", flexDirection: "row" }}>
        <FormControl>
          <InputLabel htmlFor="component-error">
            {I18n.get("label_quantity")}
          </InputLabel>
          <OutlinedInput
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            aria-describedby="component-error-text"
          />
        </FormControl>
        <Button
          variant="contained"
          onClick={setOrdersQuantityLimit}
          color="primary"
        >
          {I18n.get("action_save")}
        </Button>
      </div>
    </Fragment>
  )
}

export default BreadOrdersItem
