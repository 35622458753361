import React, { useEffect, useState } from "react"
import { I18n } from "aws-amplify"
import API, { graphqlOperation } from "@aws-amplify/api"
import { getShipment } from "../../graphql/queries"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import { getFormattedDateTime } from "../../utils/dateUtil"

const useStyles = makeStyles((theme) => ({
  root: {
    height: 750,
  },
  coloumn: {
    width: "25%",
  },
  orders: {
    width: "70%",
  },
}))

const OrderDetails = ({ order }) => {
  const classes = useStyles()
  const [shipmentNumber, setShipmentNumber] = useState("")
  useEffect(() => {
    loadShipmentNumber()
  }, [])

  const loadShipmentNumber = async () => {
    const params = {
      orderNumber: order.orderNumber,
      countryCode: order.countryCode,
      warehouseId: order.warehouseId,
    }
    try {
      const response = await API.graphql(graphqlOperation(getShipment, params))
      const shipment = response.data.getShipment
      if (shipment) {
        setShipmentNumber(response.data.getShipment.shipmentNumber)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const formatAddress = (address) => {
    if (!address) return ""
    console.log(address)
    let formattedAddress = address.firstName + " " + address.lastName
    formattedAddress =
      formattedAddress + ", " + address.street + " " + address.houseNr
    formattedAddress =
      formattedAddress + ", " + address.zipCode + " " + address.city
    return formattedAddress
  }

  const getLocalizedOrderStatus = () => {
    const statusCode = `order_status_${order.orderStatus}`
    let status = I18n.get(statusCode)
    if (order.orderStatus === "CANCELLED") {
      const reasonCode = `cancel_reason_${order.cancelReason}`
      const reason = I18n.get(reasonCode)
      status = status + "-" + reason
    }
    return status
  }

  const getLocalizedPaymentType = () => {
    if (
      order.paymentType !== "ALADDIN_INVOICE" &&
      order.paymentType !== "ALADDIN_IN_ADVANCE" &&
      order.paymentType !== "KLARNA_INVOICE"
    )
      return order.paymentType
    const aladdinPaymentType = `order_payment_type_${order.paymentType}`
    return I18n.get(aladdinPaymentType)
  }

  const getLocalizedAladdinPayment = () => {
    const statusCode = `order_aladdin_payment_status_${order.aladdinPaymentStatus}`
    return I18n.get(statusCode)
  }

  return (
    <React.Fragment>
      <Grid container>
        <Grid item className={classes.coloumn}>
          <div>
            {I18n.get("label_order_number")}: {order.orderNumber}
          </div>
        </Grid>
        <Grid item className={classes.coloumn}>
          <div>
            {I18n.get("label_customer_number")}: {order.customerNumber}
          </div>
        </Grid>
        <Grid item className={classes.coloumn}>
          <div>
            {I18n.get("label_date")}: {getFormattedDateTime(order.orderDate)}
          </div>
        </Grid>
        <Grid item className={classes.coloumn}>
          <div>
            {I18n.get("label_status")}: {getLocalizedOrderStatus()}
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item className={classes.coloumn}>
          <div>
            {I18n.get("label_payment_type")}: {getLocalizedPaymentType()}
          </div>
        </Grid>
        {order.paymentType !== "ALADDIN_INVOICE" && (
          <Grid item className={classes.coloumn}>
            <div>
              {I18n.get("label_transaction_reference")}:{" "}
              {order.transactionReference}
            </div>
          </Grid>
        )}
        {order.paymentType === "ALADDIN_INVOICE" && (
          <Grid item className={classes.coloumn}>
            <div>
              {I18n.get("label_status")}: {getLocalizedAladdinPayment()}
            </div>
          </Grid>
        )}

        <Grid item className={classes.coloumn}>
          <div>
            {I18n.get("label_invoice_number")}: {order.invoiceNumber}
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item className={classes.coloumn}>
          <div>
            {I18n.get("label_items_total")}: {order.itemsTotal}
          </div>
        </Grid>
        <Grid item className={classes.coloumn}>
          <div>
            {I18n.get("label_shiping_cost")}: {order.shippingCost}
          </div>
        </Grid>
        <Grid item className={classes.coloumn}>
          <div>
            {I18n.get("label_shiping_discount")}: {order.shippingDiscount}
          </div>
        </Grid>
        <Grid item className={classes.coloumn}>
          <div>
            {I18n.get("label_credits_promotion")}: {order.creditsPromotion}
          </div>
        </Grid>
        <Grid item className={classes.coloumn}>
          <div>
            {I18n.get("label_order_total")}: {order.orderTotal}
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item className={classes.coloumn}>
          <div>
            {I18n.get("label_shipment_type")}: {order.shipmentType}
          </div>
        </Grid>
        <Grid item>
          <div>
            <span>{I18n.get("label_shipment_number")}:</span>
            {shipmentNumber && (
              <a
                href={`https://www.dhl.de/en/privatkunden/dhl-sendungsverfolgung.html?piececode=${shipmentNumber}`}
                target="_blank"
              >
                {shipmentNumber}
              </a>
            )}
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item className={classes.coloumn}>
          <div>
            {I18n.get("label_telephone")}: {order.mobileNumber}
          </div>
        </Grid>
        <Grid item>
          <div>
            {I18n.get("label_ip_address")}: {order.ipAddress}
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item>
          <div>
            {I18n.get("label_billing_address")}:{" "}
            {formatAddress(order.billingAddress)}
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item>
          <div>
            {I18n.get("label_shipping_address")}:{" "}
            {formatAddress(order.shippingAddress)}
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default OrderDetails
