import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { I18n } from "aws-amplify"
import React, { Fragment, useEffect, useState } from "react"
import API, { graphqlOperation } from "@aws-amplify/api"
import {
  getCustomersByNumbers,
  getNMonthRetentionRate,
} from "../../graphql/queries"
import { getFormattedShortDate } from "../../utils/dateUtil"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { Bar } from "react-chartjs-2"
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const months = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
]
const useStyles = makeStyles({
  container: {
    width: "70%",
  },
  tableWrapper: {
    height: 600,
    overflow: "auto",
  },
  customerRow: {
    cursor: "pointer",
  },
})

const NMonthRetentionRate = () => {
  const tempData = {
    labels: [],
    datasets: [
      {
        label: I18n.get("label_simple"),
        data: [],
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: I18n.get("label_accumulative"),
        data: [],
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  }
  const classes = useStyles()
  const [years, setYears] = useState([])
  const [month, setMonth] = useState("01")
  const [year, setYear] = useState("2020")
  const [data, setData] = useState(tempData)
  const [onlyNewCustomers, setOnlyNewCustomers] = useState(true)
  const [customers, setCustomers] = useState([])
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: I18n.get("label_n_month_retention_rate"),
      },
    },
  }

  useEffect(() => {
    let y = []
    let theYear = 2020
    const now = new Date()
    const thisYear = now.getFullYear()
    while (theYear <= thisYear) {
      y.push(`${theYear}`)
      theYear++
    }
    setYears(y)
  }, [])
  const getData = async () => {
    const countryCode = "de"
    const warehouseId = "4331b036-b379-4cb6-86c2-59e45f34d2e1"
    const params = {
      countryCode,
      warehouseId,
      startMonth: month,
      year,
      onlyNewCustomers,
    }
    const retentionData = await API.graphql(
      graphqlOperation(getNMonthRetentionRate, params)
    )
    let labels = []
    let simpleData = []
    let accumulativeData = []
    let accumulativeValue = 0
    console.log(retentionData.data.getNMonthRetentionRate)
    let i = 0
    for (const retentionEntry of retentionData.data.getNMonthRetentionRate) {
      labels.push(retentionEntry.month)
      simpleData.push(retentionEntry.customers.length)
      if (i > 0) accumulativeValue += retentionEntry.customers.length
      accumulativeData.push(accumulativeValue)
      i++
    }
    const newData = {
      labels,
      datasets: [
        {
          label: I18n.get("label_simple"),
          data: simpleData,
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
        {
          label: I18n.get("label_accumulative"),
          data: accumulativeData,
          backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
      ],
    }
    setData(newData)
    const firstMonthData = retentionData.data.getNMonthRetentionRate[0]
    const firstMonthCustomersNumbers = firstMonthData.customers
    getCustomers(firstMonthCustomersNumbers)
  }
  const getCustomers = (customersNumbers) => {
    const params = {
      customersNumbers,
    }
    API.graphql(graphqlOperation(getCustomersByNumbers, params))
      .then((result) => {
        setCustomers(result.data.getCustomersByNumbers)
      })
      .catch((error) => console.log(error))
  }

  const openCustomerDetails = (customerNumber) => {
    window.open(`/customerDetails/${customerNumber}`, "_blank")
  }
  return (
    <Fragment>
      <Typography>{I18n.get("label_n_month_retention_rate_desc")}</Typography>
      <FormControl>
        <InputLabel shrink htmlFor="year-select">
          {I18n.get("label_year")}
        </InputLabel>
        <Select
          onChange={(e) => setYear(e.target.value)}
          value={year}
          inputProps={{
            id: "year-select",
          }}
        >
          {years.map((year, index) => (
            <MenuItem key={index} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel shrink htmlFor="month-select">
          {I18n.get("label_month")}
        </InputLabel>
        <Select
          onChange={(e) => setMonth(e.target.value)}
          value={month}
          inputProps={{
            id: "month-select",
          }}
        >
          {months.map((month, index) => (
            <MenuItem key={index} value={month}>
              {month}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={onlyNewCustomers}
              onChange={(e) => {
                setOnlyNewCustomers(!onlyNewCustomers)
              }}
              name="checkedB"
              color="primary"
            />
          }
          label={I18n.get("label_retention_only_new_customers")}
        />
      </div>
      <div>
        <Button variant="contained" color="primary" onClick={getData}>
          {I18n.get("action_run")}
        </Button>
      </div>

      <Bar options={options} data={data} />
      {customers.length > 0 && (
        <div className={classes.tableWrapper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell key="customerNumber" style={{ width: 100 }}>
                  <Typography variant="subtitle1" color="textPrimary">
                    {I18n.get("label_customer_number")}
                  </Typography>
                </TableCell>
                <TableCell key="customerNmae" style={{ width: 50 }}>
                  <Typography variant="subtitle1" color="textPrimary">
                    {I18n.get("label_name")}
                  </Typography>
                </TableCell>

                <TableCell key="lastOrderDate" style={{ width: 75 }}>
                  <Typography variant="subtitle1" color="textPrimary">
                    {I18n.get("label_last_order_date")}
                  </Typography>
                </TableCell>
                <TableCell key="ordersCount" style={{ width: 75 }}>
                  <Typography variant="subtitle1" color="textPrimary">
                    {I18n.get("label_orders_count")}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customers.map((customer) => (
                <TableRow
                  className={classes.customerRow}
                  key={customer.customerNumber}
                  onClick={(e) => openCustomerDetails(customer.customerNumber)}
                >
                  <TableCell style={{ width: 100 }}>
                    <Typography variant="subtitle1" color="textPrimary">
                      {customer.customerNumber}
                    </Typography>
                  </TableCell>
                  <TableCell style={{ width: 50 }}>
                    {customer.billingAddress && (
                      <Typography variant="subtitle1" color="textPrimary">
                        {`${customer.firstName} ${customer.lastName}`}
                      </Typography>
                    )}
                  </TableCell>

                  <TableCell style={{ width: 75 }}>
                    <Typography variant="subtitle1" color="textPrimary">
                      {getFormattedShortDate(customer.lastOrderDate)}
                    </Typography>
                  </TableCell>
                  <TableCell style={{ width: 75 }}>
                    <Typography variant="subtitle1" color="textPrimary">
                      {customer.ordersCount}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
    </Fragment>
  )
}

export default NMonthRetentionRate
