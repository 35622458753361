import API, { graphqlOperation } from "@aws-amplify/api"
import React, { Fragment, useEffect, useState } from "react"
import { I18n } from "aws-amplify"
import {
  getCustomerByNumber,
  listConversationMessages,
} from "../../graphql/queries"
import { makeStyles } from "@material-ui/core/styles"
import {
  Button,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.default,
    position: "relative",
    overflow: "auto",
    maxHeight: 400,
    marginTop: theme.spacing(1),
    direction: "rtl",
  },
  customerMessage: {
    width: "90%",
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(1),
    direction: "rtl",
  },
  aladdinMessage: {
    width: "90%",
    backgroundColor: theme.palette.primary.light,
    color: "white",
    margin: theme.spacing(1),
    direction: "rtl",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  controll: {
    marginTop: 30,
    width: 200,
  },
}))

const ConversationOverview = (props) => {
  const classes = useStyles()
  const [messages, setMessages] = useState([])
  const [customer, setCustomer] = useState()
  const [selectedEmployee, setSelectedEmployee] = useState()
  let employeesOptions = props.employees.map((employee) => {
    return { label: employee.employeeName, value: employee.username }
  })

  useEffect(() => {
    const args = {
      conversationId: props.conversation.createDate,
      countryCode: props.conversation.countryCode,
      customerNumber: props.conversation.customerNumber,
    }
    API.graphql(graphqlOperation(listConversationMessages, args))
      .then((response) => {
        const messages = response.data.listConversationMessages
        setMessages(messages)
      })
      .catch((error) => {
        console.log(error)
      })
    API.graphql(
      graphqlOperation(getCustomerByNumber, {
        customerNumber: props.conversation.customerNumber,
      })
    )
      .then((response) => {
        setCustomer(response.data.getCustomerByNumber)
      })
      .catch((error) => {
        console.log(error)
      })
    return () => {
      setSelectedEmployee()
      setCustomer()
      setMessages([])
    }
  }, [])
  const assignConversation = () => {
    console.log(JSON.stringify(selectedEmployee))
    props.assingConversationHandler(selectedEmployee)
  }
  const employeeChanged = (e) => {
    const value = e.target.value
    for (const employee of props.employees) {
      if (employee.username === value) {
        setSelectedEmployee(employee)
      }
    }
  }
  return (
    <Fragment>
      {customer ? (
        <Fragment>
          <div>
            {" "}
            {I18n.get("label_customer_number")}: {customer.customerNumber}
          </div>
          <div>
            {" "}
            {I18n.get("label_name")}: {customer.firstName} {customer.lastName}
          </div>
          <div>
            {" "}
            {I18n.get("label_username")}: <span>{customer.username}</span>
          </div>
          <div>
            {" "}
            {I18n.get("label_email")}: <span>{customer.email}</span>
          </div>
          <div>
            {" "}
            {I18n.get("label_telephone")}: <span>{customer.mobileNumber}</span>
          </div>
          <div>
            {" "}
            {I18n.get("label_customer_trust")}: <span>{customer.trust}</span>
          </div>
          <div>
            {" "}
            {I18n.get("label_has_pending_payment")}:{" "}
            <span>
              {customer.hasPendingPayment
                ? I18n.get("label_yes")
                : I18n.get("label_no")}
            </span>
          </div>
          <div>
            {" "}
            {I18n.get("label_customer_friendshipCode")}:{" "}
            {customer.friendshipCode}
          </div>
          <div>
            {" "}
            {I18n.get("label_customer_myFriendCode")}:{" "}
            {customer.myFriendCode
              ? customer.myFriendCode
              : I18n.get("label_not_exists")}
          </div>
          <div>
            {" "}
            {I18n.get("label_customer_isMyFriendCodeUsed")}:{" "}
            {customer.isMyFriendCodeUsed
              ? I18n.get("label_yes")
              : I18n.get("label_no")}
          </div>
          <div>
            {I18n.get("label_credits")}:{" "}
            {customer.credits ? customer.credits : 0}
          </div>
        </Fragment>
      ) : (
        <div>{I18n.get("label_customer_not_registered")}</div>
      )}

      {props.assingConversationHandler && (
        <Fragment>
          <FormControl className={classes.controll}>
            <InputLabel shrink htmlFor="employee-select">
              {I18n.get("label_select_employee")}
            </InputLabel>
            <Select
              onChange={employeeChanged}
              value={selectedEmployee ? selectedEmployee.username : ""}
              displayEmpty
              className={classes.selectEmpty}
              inputProps={{
                id: "employee-select",
              }}
            >
              <MenuItem key="empty-des" value="">
                ---
              </MenuItem>
              {employeesOptions.map((employeeOption, index) => (
                <MenuItem key={index} value={employeeOption.value}>
                  {employeeOption.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            className={classes.controll}
            variant="contained"
            onClick={assignConversation}
            color="primary"
          >
            {I18n.get("action_assign_conversation")}
          </Button>
        </Fragment>
      )}
      {props.conversation.conversationStatus === "CLOSED" && (
        <div>
          <p>{I18n.get("message_conversation_closed_with_measures")}</p>
          <p>{props.conversation.measures}</p>
        </div>
      )}
      <List className={classes.root}>
        {messages.map((message, index) => (
          <ListItem
            className={
              message.sender === "CUSTOMER"
                ? classes.customerMessage
                : classes.aladdinMessage
            }
            key={index}
          >
            <ListItemText primary={message.message} />
          </ListItem>
        ))}
      </List>
    </Fragment>
  )
}
export default ConversationOverview
