import React, { useContext, useReducer, useEffect, useState } from "react"

import { I18n } from "aws-amplify"
import { API, graphqlOperation } from "aws-amplify"

import GlobalContext from "../../context/global-context"
import ItemsContext from "../../context/items-context"
import itemsReducer from "../../reducers/items"

import { listRunOutWarehouse } from "../../graphql/queries"
import { ignoreRecurring } from "../../graphql/mutations"
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core"

const RundOutWarehouse = (props) => {
  const [{ runOutWarehouse }, dispatch] = useReducer(itemsReducer, {
    runOutWarehouse: [],
  })
  const { currentUser, warehouse } = useContext(GlobalContext)
  const [threshold, setThreshold] = useState(5)

  useEffect(() => {
    loadRundOutWarehouse()
  }, [])

  const loadRundOutWarehouse = async () => {
    const params = {
      warehouseId: warehouse.id,
      userLocale: currentUser.locale,
      threshold: threshold,
    }
    try {
      const response = await API.graphql(
        graphqlOperation(listRunOutWarehouse, params)
      )
      dispatch({
        type: "LOAD_RUN_OUT_WAREHOUSE",
        items: response.data.listRunOutWarehouse.items,
      })
    } catch (error) {
      console.log(error.errors)
    }
  }
  const toIgnoreRecurring = async (item) => {
    const args = {
      warehouseId: warehouse.id,
      sku: item.sku,
      toIgnore: !item.ignoreRecurring,
    }

    const response = await API.graphql(graphqlOperation(ignoreRecurring, args))
    if (response.data.ignoreRecurring) {
      item.ignoreRecurring = !item.ignoreRecurring
      dispatch({
        type: "UPDATE_ITEM_RUN_OUT_WAREHOUSE",
        item: item,
      })
    }
  }

  return (
    <Paper>
      <Grid container>
        <Grid item>
          <TextField
            variant="outlined"
            value={threshold}
            onChange={(e) => setThreshold(e.target.value)}
          />
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={loadRundOutWarehouse}>
            {I18n.get("action_search")}
          </Button>
        </Grid>
      </Grid>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>{I18n.get("label_sku")}</TableCell>
              <TableCell>{I18n.get("label_name")}</TableCell>
              <TableCell>{I18n.get("label_quantity")}</TableCell>
              <TableCell>{I18n.get("label_actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {runOutWarehouse && (
              <React.Fragment>
                {runOutWarehouse.map((item, index) => (
                  <TableRow
                    key={index}
                    style={{
                      backgroundColor: !!item.ignoreRecurring
                        ? "yellow"
                        : "transparent",
                    }}
                  >
                    <TableCell>{item.sku}</TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.quantity}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        onClick={(e) => toIgnoreRecurring(item)}
                      >
                        {!!item.ignoreRecurring
                          ? I18n.get("action_not_ignore_recurring")
                          : I18n.get("action_ignore_recurring")}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default RundOutWarehouse
