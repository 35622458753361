import React from "react"

import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Typography from "@material-ui/core/Typography"

import { I18n } from "aws-amplify"

import Order from "./Order"

const useStyles = makeStyles({
  tableWrapper: {
    height: 600,
    overflow: "auto",
  },
})

const OrdersList = ({ orders, clickHandler }) => {
  const classes = useStyles()

  return (
    <div className={classes.tableWrapper}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell key="orderNumber" style={{ width: 150 }}>
              <Typography variant="subtitle1" color="textPrimary">
                {I18n.get("label_order_number")}
              </Typography>
            </TableCell>
            <TableCell key="orderDate" style={{ width: 100 }}>
              <Typography variant="subtitle1" color="textPrimary">
                {I18n.get("label_date")}
              </Typography>
            </TableCell>
            <TableCell key="customerNumber" style={{ width: 100 }}>
              <Typography variant="subtitle1" color="textPrimary">
                {I18n.get("label_order_total")}
              </Typography>
            </TableCell>
            <TableCell key="customerLanguage" style={{ width: 75 }}>
              <Typography variant="subtitle1" color="textPrimary">
                {I18n.get("label_language")}
              </Typography>
            </TableCell>
            <TableCell key="orderStatus" style={{ width: 50 }}>
              <Typography variant="subtitle1" color="textPrimary">
                {I18n.get("label_status")}
              </Typography>
            </TableCell>

            <TableCell key="paymentType" style={{ width: 75 }}>
              <Typography variant="subtitle1" color="textPrimary">
                {I18n.get("label_payment_type")}
              </Typography>
            </TableCell>
            <TableCell key="paymentWarning" style={{ width: 50 }}>
              <Typography variant="subtitle1" color="textPrimary">
                {I18n.get("label_warnings")}
              </Typography>
            </TableCell>
            <TableCell key="shipmentType" style={{ width: 75 }}>
              <Typography variant="subtitle1" color="textPrimary">
                {I18n.get("label_shipment_type")}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((order) => (
            <Order
              key={order.orderNumber}
              order={order}
              clickHandler={clickHandler}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  )
}
export default OrdersList
