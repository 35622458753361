/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onAssignConversation = /* GraphQL */ `
  subscription OnAssignConversation($employeeId: String) {
    onAssignConversation(employeeId: $employeeId) {
      aladdinUnreadMessages
      conversationStatus
      countryCode
      createDate
      customerLocale
      customerMobile
      customerNumber
      customerUnreadMessages
      employeeId
      employeeName
      lastMessageDate
      measures
      source
    }
  }
`;
export const onCloseConversation = /* GraphQL */ `
  subscription OnCloseConversation($createDate: String) {
    onCloseConversation(createDate: $createDate) {
      aladdinUnreadMessages
      conversationStatus
      countryCode
      createDate
      customerLocale
      customerMobile
      customerNumber
      customerUnreadMessages
      employeeId
      employeeName
      lastMessageDate
      measures
      source
    }
  }
`;
export const onCreateConversation = /* GraphQL */ `
  subscription OnCreateConversation {
    onCreateConversation {
      aladdinUnreadMessages
      conversationStatus
      countryCode
      createDate
      customerLocale
      customerMobile
      customerNumber
      customerUnreadMessages
      employeeId
      employeeName
      lastMessageDate
      measures
      source
    }
  }
`;
export const onItemAddedRemovedToCart = /* GraphQL */ `
  subscription OnItemAddedRemovedToCart($warehouseId: String!) {
    onItemAddedRemovedToCart(warehouseId: $warehouseId) {
      quantity
      sku
      warehouseId
    }
  }
`;
export const onMessageCreated = /* GraphQL */ `
  subscription OnMessageCreated($sender: Sender, $ticketId: String!) {
    onMessageCreated(sender: $sender, ticketId: $ticketId) {
      employeeId
      employeeName
      message
      sender
      sentDate
      ticketId
      type
    }
  }
`;
export const onOrderBeingCollected = /* GraphQL */ `
  subscription OnOrderBeingCollected($warehouseId: String!) {
    onOrderBeingCollected(warehouseId: $warehouseId) {
      VATValues {
        category
        value
      }
      aladdinPaymentStatus
      billingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      cancelReason
      countryCode
      couponCode
      couponName
      couponValue
      creditsPromotion
      customerFirstOrder
      customerLocale
      customerNumber
      dealsValue
      invoice
      invoiceNumber
      ipAddress
      items {
        VAT
        VATValue
        barcode
        cost
        discount
        discountRate
        finalNetPrice
        finalPrice
        finalVatValue
        listPrice
        name
        netPrice
        offerId
        orderDate
        orderNumber
        quantity
        sku
      }
      itemsTotal
      loyalityProgramCredits
      mobileNumber
      orderDate
      orderNumber
      orderStatus
      orderTotal
      packets
      paymentType
      shipmentType
      shippingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      shippingCost
      shippingDiscount
      shippingVatValue
      statuses {
        date
        employeeId
        employeeName
        orderNumber
        status
      }
      subtotal
      transactionReference
      verifyCustomer
      warehouseId
    }
  }
`;
export const onPostConversationMessageByConversation = /* GraphQL */ `
  subscription OnPostConversationMessageByConversation(
    $conversationId: String
    $sender: Sender
  ) {
    onPostConversationMessageByConversation(
      conversationId: $conversationId
      sender: $sender
    ) {
      conversationId
      countryCode
      customerNumber
      employeeId
      employeeName
      message
      read
      sender
      sentDate
      type
    }
  }
`;
export const onTicketAssignedInCountry = /* GraphQL */ `
  subscription OnTicketAssignedInCountry($countryCode: String) {
    onTicketAssignedInCountry(countryCode: $countryCode) {
      countryCode
      createDate
      customerLocale
      customerNumber
      employeeId
      employeeName
      messurement
      ticketId
      ticketStatus
    }
  }
`;
export const onTicketAssignedOfCustomer = /* GraphQL */ `
  subscription OnTicketAssignedOfCustomer($customerNumber: String) {
    onTicketAssignedOfCustomer(customerNumber: $customerNumber) {
      countryCode
      createDate
      customerLocale
      customerNumber
      employeeId
      employeeName
      messurement
      ticketId
      ticketStatus
    }
  }
`;
export const onTicketClosed = /* GraphQL */ `
  subscription OnTicketClosed($ticketId: String!) {
    onTicketClosed(ticketId: $ticketId) {
      countryCode
      createDate
      customerLocale
      customerNumber
      employeeId
      employeeName
      messurement
      ticketId
      ticketStatus
    }
  }
`;
export const onTicketCreatedInCountry = /* GraphQL */ `
  subscription OnTicketCreatedInCountry($countryCode: String!) {
    onTicketCreatedInCountry(countryCode: $countryCode) {
      countryCode
      createDate
      customerLocale
      customerNumber
      employeeId
      employeeName
      messurement
      ticketId
      ticketStatus
    }
  }
`;
export const onTicketCreatedInCountryWithLocale = /* GraphQL */ `
  subscription OnTicketCreatedInCountryWithLocale(
    $countryCode: String!
    $customerLocale: String!
  ) {
    onTicketCreatedInCountryWithLocale(
      countryCode: $countryCode
      customerLocale: $customerLocale
    ) {
      countryCode
      createDate
      customerLocale
      customerNumber
      employeeId
      employeeName
      messurement
      ticketId
      ticketStatus
    }
  }
`;
