import React, { useEffect, useState } from "react"
import API, { graphqlOperation } from "@aws-amplify/api"
import { I18n } from "aws-amplify"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"

import CustomerInfo from "../customer/CustomerInfo"
import CustomerOrdersPage from "../customer/CustomerOrdersPage"
import {
  getCustomerByNumber,
  listConversationsByCustomer,
} from "../../graphql/queries"
import ConversationsList from "../conversation/ConversationsList"
import { openConversation } from "../../graphql/mutations"

const useStyles = makeStyles((theme) => ({
  info: {
    width: "30%",
  },
  orders: {
    width: "70%",
  },
  grid: {
    padding: theme.spacing(5),
    margin: theme.spacing(1),
  },
}))

const CustomerPage = (props) => {
  const {
    match: { params },
  } = props
  const customerNumber = params.customerNumber
  const classes = useStyles()
  const [customer, setCustomer] = useState()
  const [conversations, setConversations] = useState([])
  const [showConversations, setShowConversations] = useState(false)
  let subscribed = true
  useEffect(() => {
    getCustomer()
    return () => {
      subscribed = false
    }
  }, [])

  useEffect(() => {
    if (showConversations && conversations.length === 0) {
      getConversations()
    }
  }, [showConversations])

  const getCustomer = async () => {
    const params = {
      customerNumber,
    }
    const response = await API.graphql(
      graphqlOperation(getCustomerByNumber, params)
    )
    const fetchedCustomer = response.data.getCustomerByNumber
    if (fetchedCustomer && subscribed) {
      setCustomer(fetchedCustomer)
    }
  }

  const onConversationClicked = (conversation) => {
    if (conversation.conversationStatus === "ASSIGNED")
      setConversationOpen(conversation)
    // const stringifiedConversation = JSON.stringify(conversation)
    // const encodedConversation = btoa(stringifiedConversation)
    // props.history.push(`/open-conversation/${encodedConversation}`)
    props.history.push(
      `/open-conversation/${conversation.customerNumber}/${conversation.countryCode}/${conversation.createDate}`
    )
  }
  const setConversationOpen = (conversation) => {
    const args = {
      conversationId: conversation.createDate,
      customerNumber: conversation.customerNumber,
      countryCode: conversation.countryCode,
    }
    API.graphql(graphqlOperation(openConversation, args))
      .then((response) => {})
      .catch((error) => console.log(error))
  }
  const getConversations = () => {
    const args = {
      customerNumber: customer.customerNumber,
    }
    API.graphql(graphqlOperation(listConversationsByCustomer, args))
      .then((response) => {
        if (subscribed)
          setConversations(response.data.listConversationsByCustomer)
      })
      .catch((error) => console.log(error))
  }

  return (
    <React.Fragment>
      {customer && (
        <Grid
          className={classes.grid}
          container
          spacing={3}
          justify="space-between"
        >
          <Grid item xs={12}>
            <CustomerInfo customer={customer} />
          </Grid>
          <Grid item xs={12}>
            <p
              style={{ cursor: "pointer", color: "blue" }}
              onClick={(e) => setShowConversations(!showConversations)}
            >
              {I18n.get("label_conversations")}
            </p>
            {showConversations && (
              <ConversationsList
                conversations={conversations}
                onClick={onConversationClicked}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <CustomerOrdersPage customer={customer} />
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  )
}

export default CustomerPage
