const funnels = [
  {
    name: "This funnel shows the path for both Web and Android platforms: Start Session -> Start shopping => Start Checkout -> Start Payment -> Complete Checkout",
    series: [
      "_session.start",
      "ShoppingStart",
      "CheckoutStart",
      "PaymentStart",
      "CheckoutCompleted",
    ],
    platforms: ["web", "android"],
  },
  {
    name: "This funnel shows the path for Web platform: Start Session -> Start shopping => Start Checkout -> Start Payment -> Complete Checkout",
    series: [
      "_session.start",
      "ShoppingStart",
      "CheckoutStart",
      "PaymentStart",
      "CheckoutCompleted",
    ],
    platforms: ["web"],
  },
  {
    name: "This funnel shows the path for Android platform: Start Session -> Start shopping => Start Checkout -> Start Payment -> Complete Checkout",
    series: [
      "_session.start",
      "ShoppingStart",
      "CheckoutStart",
      "PaymentStart",
      "CheckoutCompleted",
    ],
    platforms: ["android"],
  },
  {
    name: "This funnel shows the path for both Web and Android platforms: Start Session -> Start shopping -> Complete Checkout",
    series: ["_session.start", "ShoppingStart", "CheckoutCompleted"],
    platforms: ["web", "android"],
  },
  {
    name: "This funnel shows the path for Web platform: Start Session -> Start shopping -> Complete Checkout",
    series: ["_session.start", "ShoppingStart", "CheckoutCompleted"],
    platforms: ["web"],
  },
  {
    name: "This funnel shows the path for Android platform: Start Session -> Start shopping -> Complete Checkout",
    series: ["_session.start", "ShoppingStart", "CheckoutCompleted"],
    platforms: ["android"],
  },
  {
    name: "This funnel shows the path for both Android and we platforms: Start Session -> Start shopping => Start Checkout -> Start Payment -> Payment Rejected -> Add Address -> Complete Checkout",
    series: [
      "ShoppingStart",
      "CheckoutStart",
      "PaymentRejected",
      "AddAddressStart",
      "AddAddressCompleted",
      "CheckoutCompleted",
    ],
    platforms: ["android", "web"],
  },
  {
    name: "This funnel shows the path for web platform: Start Session -> Start shopping => Start Checkout -> Start Payment -> Payment Rejected -> Add Address -> Complete Checkout",
    series: [
      "ShoppingStart",
      "CheckoutStart",
      "PaymentRejected",
      "AddAddressStart",
      "AddAddressCompleted",
      "CheckoutCompleted",
    ],
    platforms: ["web"],
  },
  {
    name: "This funnel shows the path for Android platform: Start Session -> Start shopping => Start Checkout -> Start Payment -> Payment Rejected -> Add Address -> Complete Checkout",
    series: [
      "ShoppingStart",
      "CheckoutStart",
      "PaymentRejected",
      "AddAddressStart",
      "AddAddressCompleted",
      "CheckoutCompleted",
    ],
    platforms: ["android"],
  },
  {
    name: "This funnel shows the path for both Android and we platforms: Start Session -> Start shopping => Start Checkout -> Start Payment -> Payment Rejected -> Add Contact -> Complete Checkout",
    series: [
      "ShoppingStart",
      "CheckoutStart",
      "PaymentRejected",
      "AddContactStart",
      "ContactCompleted",
      "CheckoutCompleted",
    ],
    platforms: ["android", "web"],
  },
  {
    name: "This funnel shows the path for web platform: Start Session -> Start shopping => Start Checkout -> Start Payment -> Payment Rejected -> Add Contact -> Complete Checkout",
    series: [
      "ShoppingStart",
      "CheckoutStart",
      "PaymentRejected",
      "AddContactStart",
      "ContactCompleted",
      "CheckoutCompleted",
    ],
    platforms: ["web"],
  },
  {
    name: "This funnel shows the path for Android platform: Start Session -> Start shopping => Start Checkout -> Start Payment -> Payment Rejected -> Add Contact -> Complete Checkout",
    series: [
      "ShoppingStart",
      "CheckoutStart",
      "PaymentRejected",
      "AddContactStart",
      "ContactCompleted",
      "CheckoutCompleted",
    ],
    platforms: ["android"],
  },
  {
    name: "This funnel shows the path for Android platform: Start Session -> Start shopping -> Address Rejected -> Complete Checkout",
    series: ["ShoppingStart", "AddressInvalid", "CheckoutCompleted"],
    platforms: ["android"],
  },
  {
    name: "This funnel shows the path for Web platform: Start Session -> Start shopping -> Address Rejected -> Complete Checkout",
    series: ["ShoppingStart", "AddressInvalid", "CheckoutCompleted"],
    platforms: ["web"],
  },
  {
    name: "This funnel shows the path for both Android and Web platform: Start Session -> Start shopping -> Address Rejected -> Complete Checkout",
    series: ["ShoppingStart", "AddressInvalid", "CheckoutCompleted"],
    platforms: ["android", "web"],
  },
]
export default funnels
