import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@material-ui/core"
import { I18n } from "aws-amplify"
import React, { Fragment, useEffect, useState } from "react"
import API, { graphqlOperation } from "@aws-amplify/api"
import {
  getUnboundRententionData,
  getUnreadConversationMessages,
} from "../../graphql/queries"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { Bar, Line } from "react-chartjs-2"
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
)

const months = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
]
const days = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
]

const UnboundedRetentionRate = () => {
  const dataOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: I18n.get("label_retention_data"),
      },
    },
  }
  const ratesOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: I18n.get("label_retention_rate"),
      },
    },
  }
  const ratesToOrdersOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: I18n.get("label_retention_rate_to_orders"),
      },
    },
  }
  const calculatedRevenueOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: I18n.get("label_calculated_orders_revenue"),
      },
    },
  }
  const tempCountsData = {
    labels: [],
    datasets: [
      {
        label: I18n.get("label_orders"),
        data: [],
        backgroundColor: "rgba(54, 162, 235, 0.5)",
      },
      {
        label: I18n.get("label_existing_customers"),
        data: [],
        backgroundColor: "rgba(255, 159, 64, 0.5)",
      },
      {
        label: I18n.get("label_returned_customers"),
        data: [],
        backgroundColor: "rgba(75, 192, 192, 0.5)",
      },
      {
        label: I18n.get("label_new_customers"),
        data: [],
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  }
  const tempRates = {
    labels: [],
    datasets: [
      {
        label: I18n.get("label_retention_rate"),
        data: [],
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  }
  const tempRatesToOrders = {
    labels: [],
    datasets: [
      {
        label: I18n.get("label_retention_rate_to_orders"),
        data: [],
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  }
  const tempCalculatedRevenueData = {
    labels: [],
    datasets: [
      {
        label: I18n.get("label_current_orders"),
        data: [],
        backgroundColor: "rgba(54, 162, 235, 0.5)",
      },
      {
        label: I18n.get("label_current_orders_revenue"),
        data: [],
        backgroundColor: "rgba(255, 159, 64, 0.5)",
      },
      {
        label: I18n.get("label_calculated_orders"),
        data: [],
        backgroundColor: "rgba(75, 192, 192, 0.5)",
      },
      {
        label: I18n.get("label_calculated_orders_revenue"),
        data: [],
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  }

  const [period, setPeriod] = useState("MONTHLY")
  const [years, setYears] = useState([])
  const [day, setDay] = useState("01")
  const [month, setMonth] = useState("01")
  const [year, setYear] = useState("2020")
  const [rawData, setRawData] = useState()
  const [countsData, setCountsData] = useState(tempCountsData)
  const [rates, setRates] = useState(tempRates)
  const [ratesToOrders, setRatesToOrders] = useState(tempRatesToOrders)
  const [calculatedRevenueData, setCalculatedRevenueData] = useState(
    tempCalculatedRevenueData
  )
  const [fixedRetentionRate, setFixedRetentionRate] = useState(20)
  const [orderTotal, setOrderTotal] = useState(50)
  const [totalOrignialRevenue, setTotalOriginalRevenue] = useState(0)
  const [calculatedTotalRevenue, setCalculatedTotalRevenue] = useState(0)
  useEffect(() => {
    let y = []
    let theYear = 2020
    const now = new Date()
    const thisYear = now.getFullYear()
    while (theYear <= thisYear) {
      y.push(`${theYear}`)
      theYear++
    }
    setYears(y)
  }, [])
  const getData = async () => {
    const countryCode = "de"
    const warehouseId = "4331b036-b379-4cb6-86c2-59e45f34d2e1"
    let forDate = year
    switch (period) {
      case "WEEKLY":
        forDate = `${year}-${month}-${day}`
        break
      case "MONTHLY":
        forDate = `${year}-${month}`
        break
      case "QUARTERLY":
        forDate = year
        break
    }
    const params = {
      countryCode,
      warehouseId,
      period,
      forDate,
    }
    const response = await API.graphql(
      graphqlOperation(getUnboundRententionData, params)
    )
    const returnedData = response.data.getUnboundRententionData
    let labels = []
    let orders = []
    let newCustomers = []
    let returnedCustomers = []
    let existingCustomers = []
    let rententionRates = []
    let retentionRatesToOrders = []
    for (let entry of returnedData) {
      labels.push(entry.period)
      const entryNewCustomers = entry.retentionData.newCustomers
      const entryExistingCustomers = entry.retentionData.existingCustomers
      const entryReturnedCustomers = entry.retentionData.returnedCustomers
      newCustomers.push(entryNewCustomers)
      returnedCustomers.push(entryReturnedCustomers)
      existingCustomers.push(entryExistingCustomers)
      orders.push(entryNewCustomers + entryReturnedCustomers)
      const entryRetentionRate = parseInt(
        (100 * (entryReturnedCustomers + entryNewCustomers)) /
          (entryExistingCustomers + entryNewCustomers)
      )
      rententionRates.push(entryRetentionRate)
      const entryRetentionRateToOrder = parseInt(
        (100 * entryReturnedCustomers) /
          (entryReturnedCustomers + entryNewCustomers)
      )
      retentionRatesToOrders.push(entryRetentionRateToOrder)
    }
    const newCountsData = {
      labels: labels,
      datasets: [
        {
          label: I18n.get("label_orders"),
          data: orders,
          backgroundColor: "rgba(54, 162, 235, 0.5)",
        },
        {
          label: I18n.get("label_existing_customers"),
          data: existingCustomers,
          backgroundColor: "rgba(255, 159, 64, 0.5)",
        },
        {
          label: I18n.get("label_returned_customers"),
          data: returnedCustomers,
          backgroundColor: "rgba(75, 192, 192, 0.5)",
        },
        {
          label: I18n.get("label_new_customers"),
          data: newCustomers,
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
      ],
    }
    const newRates = {
      labels: labels,
      datasets: [
        {
          label: I18n.get("label_retention_rate"),
          data: rententionRates,
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
      ],
    }
    const newRatesToOrders = {
      labels: labels,
      datasets: [
        {
          label: I18n.get("label_retention_rate_to_orders"),
          data: retentionRatesToOrders,
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
      ],
    }
    setCountsData(newCountsData)
    setRates(newRates)
    setRatesToOrders(newRatesToOrders)
    setRawData(returnedData)
  }
  const getCalculatedRevenue = () => {
    if (!rawData || !fixedRetentionRate || !orderTotal) return
    let originalRev = 0
    let calculatedRev = 0
    let currentOrders = []
    let currentOrdersRevenue = []
    let calculatedOrders = []
    let calculatedOrdersRevenue = []
    for (let entry of rawData) {
      const entryNewCustomers = entry.retentionData.newCustomers
      const entryExistingCustomers = entry.retentionData.existingCustomers
      const entryReturnedCustomers = entry.retentionData.returnedCustomers
      const currentOrdersCount = entryNewCustomers + entryReturnedCustomers
      currentOrders.push(currentOrdersCount)
      currentOrdersRevenue.push(currentOrdersCount * orderTotal)
      originalRev += currentOrdersCount * orderTotal
      const calculatedReturned = parseInt(
        (fixedRetentionRate * (entryNewCustomers + entryExistingCustomers) -
          100 * entryNewCustomers) /
          100
      )
      const calculatedOrderCount = calculatedReturned + entryNewCustomers
      calculatedOrders.push(calculatedOrderCount)
      calculatedOrdersRevenue.push(calculatedOrderCount * orderTotal)

      calculatedRev += calculatedOrderCount * orderTotal
    }
    const newCalculatedRevenueData = {
      labels: countsData.labels,
      datasets: [
        {
          label: I18n.get("label_current_orders"),
          data: currentOrders,
          backgroundColor: "rgba(54, 162, 235, 0.5)",
        },
        {
          label: I18n.get("label_current_orders_revenue"),
          data: currentOrdersRevenue,
          backgroundColor: "rgba(255, 159, 64, 0.5)",
        },
        {
          label: I18n.get("label_calculated_orders"),
          data: calculatedOrders,
          backgroundColor: "rgba(75, 192, 192, 0.5)",
        },
        {
          label: I18n.get("label_calculated_orders_revenue"),
          data: calculatedOrdersRevenue,
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
      ],
    }
    setTotalOriginalRevenue(originalRev)
    setCalculatedTotalRevenue(calculatedRev)
    setCalculatedRevenueData(newCalculatedRevenueData)
  }
  return (
    <Fragment>
      <Typography>{I18n.get("label_unbounded_retention_rate_desc")}</Typography>
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={period}
          onChange={(e) => setPeriod(e.target.value)}
        >
          <FormControlLabel
            value="WEEKLY"
            control={<Radio />}
            label={I18n.get("label_weekly")}
          />
          <FormControlLabel
            value="MONTHLY"
            control={<Radio />}
            label={I18n.get("label_monthly")}
          />
          <FormControlLabel
            value="QUARTERLY"
            control={<Radio />}
            label={I18n.get("label_quarterly")}
          />
        </RadioGroup>
      </FormControl>
      <FormControl>
        <InputLabel shrink htmlFor="year-select">
          {I18n.get("label_year")}
        </InputLabel>
        <Select
          onChange={(e) => setYear(e.target.value)}
          value={year}
          inputProps={{
            id: "year-select",
          }}
        >
          {years.map((year, index) => (
            <MenuItem key={index} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {period != "QUARTERLY" && (
        <FormControl>
          <InputLabel shrink htmlFor="month-select">
            {I18n.get("label_month")}
          </InputLabel>
          <Select
            onChange={(e) => setMonth(e.target.value)}
            value={month}
            inputProps={{
              id: "month-select",
            }}
          >
            {months.map((month, index) => (
              <MenuItem key={index} value={month}>
                {month}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {period === "WEEKLY" && (
        <FormControl>
          <InputLabel shrink htmlFor="day-select">
            {I18n.get("label_day")}
          </InputLabel>
          <Select
            onChange={(e) => setDay(e.target.value)}
            value={day}
            inputProps={{
              id: "day-select",
            }}
          >
            {days.map((day, index) => (
              <MenuItem key={index} value={day}>
                {day}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <div>
        <Button variant="contained" color="primary" onClick={getData}>
          {I18n.get("action_run")}
        </Button>
      </div>

      <Bar options={dataOptions} data={countsData} />
      <div style={{ marginTop: 50 }} />
      <Line options={ratesOptions} data={rates} />
      <div style={{ marginTop: 50 }} />
      <Line options={ratesToOrdersOptions} data={ratesToOrders} />
      <div style={{ marginTop: 50 }}>
        <TextField
          style={{ padding: 20, margin: 10 }}
          label={I18n.get("label_retention_rate")}
          value={fixedRetentionRate}
          onChange={(e) => setFixedRetentionRate(e.target.value)}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
        <TextField
          style={{ padding: 20, margin: 10 }}
          label={I18n.get("label_avg_total")}
          value={orderTotal}
          onChange={(e) => setOrderTotal(e.target.value)}
        />
      </div>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={getCalculatedRevenue}
        >
          {I18n.get("action_run")}
        </Button>
      </div>
      <div>
        <Typography>
          {I18n.get("label_current_orders_revenue")}: {totalOrignialRevenue}
        </Typography>
        <Typography>
          {I18n.get("label_calculated_orders_revenue")}:{" "}
          {calculatedTotalRevenue}
        </Typography>
      </div>
      <Bar options={calculatedRevenueOptions} data={calculatedRevenueData} />
    </Fragment>
  )
}

export default UnboundedRetentionRate
