const comparatorByName = (a, b) => {
  return a.name > b.name ? 1 : -1
}
const itemsReducer = (state, action) => {
  switch (action.type) {
    case "LOAD_RUN_OUT_SEHLVES":
      return { ...state, runOutShelves: action.items }
    case "LOAD_RUN_OUT_WAREHOUSE":
      return { ...state, runOutWarehouse: action.items }
    case "UPDATE_ITEM_RUN_OUT_WAREHOUSE":
      const newItems = state.runOutWarehouse.map((item) => {
        if (item.sku === action.item.sku) return action.item
        return item
      })
      return { ...state, runOutWarehouse: newItems }
    case "MOVED_TO_SHELF": {
      const result = state.runOutShelves.filter((item) => {
        if (item.sku !== action.sku) {
          return item
        }
      })
      return { ...state, runOutShelves: result }
    }
    case "LOAD_ITEMS": {
      let sorted = action.items
      return { ...state, items: sorted.sort(comparatorByName) }
    }
    case "SET_PARENT": {
      return { ...state, parent: action.parent }
    }
    case "CLEAR_ITEMS": {
      return { ...state, items: [] }
    }
    case "UPDATE_ITEM": {
      let newItemsList = state.items.map((item) => {
        if (item.sku === action.item.sku) {
          return action.item
        }
        return item
      })
      return { ...state, items: newItemsList }
    }
    default:
      return state
  }
}

export default itemsReducer
