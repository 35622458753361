/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const activateDeactivateCoupon = /* GraphQL */ `
  mutation ActivateDeactivateCoupon($code: String!, $isActive: Boolean) {
    activateDeactivateCoupon(code: $code, isActive: $isActive) {
      code
      countryCode
      couponTarget
      couponType
      createDate
      customerNumber
      customers
      description
      endDate
      isActive
      name
      orderTotalLimit
      orders
      ordersCount
      reusable
      sku
      value
      warehouseId
    }
  }
`;
export const addBannerImage = /* GraphQL */ `
  mutation AddBannerImage(
    $countryCode: String!
    $id: String!
    $locale: String!
    $warehouseId: String!
  ) {
    addBannerImage(
      countryCode: $countryCode
      id: $id
      locale: $locale
      warehouseId: $warehouseId
    ) {
      active
      appDestination
      countryCode
      id
      locales
      parameter
      warehouseId
    }
  }
`;
export const addCreditsToCustomer = /* GraphQL */ `
  mutation AddCreditsToCustomer($input: CreateCreditsHistoryInput!) {
    addCreditsToCustomer(input: $input) {
      createDate
      credits
      customerNumber
      description
      employeeId
      employeeName
      friendNumber
      orderNumber
    }
  }
`;
export const addItemToCart = /* GraphQL */ `
  mutation AddItemToCart(
    $customerNumber: String!
    $quantity: Int!
    $quantityDiff: Int!
    $sku: String!
    $warehouseId: String!
  ) {
    addItemToCart(
      customerNumber: $customerNumber
      quantity: $quantity
      quantityDiff: $quantityDiff
      sku: $sku
      warehouseId: $warehouseId
    ) {
      quantity
      sku
      warehouseId
    }
  }
`;
export const addItemToCustomerCart = /* GraphQL */ `
  mutation AddItemToCustomerCart(
    $customerNumber: String!
    $quantity: Int!
    $sku: String!
    $warehouseId: String!
  ) {
    addItemToCustomerCart(
      customerNumber: $customerNumber
      quantity: $quantity
      sku: $sku
      warehouseId: $warehouseId
    )
  }
`;
export const addItemToFavorite = /* GraphQL */ `
  mutation AddItemToFavorite($customerNumber: String!, $sku: String!) {
    addItemToFavorite(customerNumber: $customerNumber, sku: $sku)
  }
`;
export const addItemToOccasion = /* GraphQL */ `
  mutation AddItemToOccasion(
    $occasionId: String!
    $sku: String!
    $warehouseId: String!
  ) {
    addItemToOccasion(
      occasionId: $occasionId
      sku: $sku
      warehouseId: $warehouseId
    )
  }
`;
export const addOrderToStatistics = /* GraphQL */ `
  mutation AddOrderToStatistics(
    $countryCode: String!
    $customerNumber: String!
    $newCustomer: Boolean!
    $orderDate: String!
    $orderTotal: Float!
    $warehouseId: String!
  ) {
    addOrderToStatistics(
      countryCode: $countryCode
      customerNumber: $customerNumber
      newCustomer: $newCustomer
      orderDate: $orderDate
      orderTotal: $orderTotal
      warehouseId: $warehouseId
    )
  }
`;
export const addProductImage = /* GraphQL */ `
  mutation AddProductImage(
    $image: String!
    $sku: String!
    $userLocale: String!
  ) {
    addProductImage(image: $image, sku: $sku, userLocale: $userLocale)
  }
`;
export const amortizeItem = /* GraphQL */ `
  mutation AmortizeItem(
    $cost: Float!
    $employeeId: String!
    $employeeName: String!
    $providerId: String!
    $quantity: Int!
    $reason: AmortizationReason!
    $sku: String!
    $warehouseId: String!
  ) {
    amortizeItem(
      cost: $cost
      employeeId: $employeeId
      employeeName: $employeeName
      providerId: $providerId
      quantity: $quantity
      reason: $reason
      sku: $sku
      warehouseId: $warehouseId
    )
  }
`;
export const assignConversation = /* GraphQL */ `
  mutation AssignConversation(
    $conversationId: String!
    $countryCode: String!
    $customerNumber: String!
    $employeeId: String!
    $employeeName: String!
  ) {
    assignConversation(
      conversationId: $conversationId
      countryCode: $countryCode
      customerNumber: $customerNumber
      employeeId: $employeeId
      employeeName: $employeeName
    ) {
      aladdinUnreadMessages
      conversationStatus
      countryCode
      createDate
      customerLocale
      customerMobile
      customerNumber
      customerUnreadMessages
      employeeId
      employeeName
      lastMessageDate
      measures
      source
    }
  }
`;
export const assignTicket = /* GraphQL */ `
  mutation AssignTicket(
    $countryCode: String!
    $createDate: String!
    $customerNumber: String!
    $employeeId: String!
    $employeeName: String!
    $ticketId: String
  ) {
    assignTicket(
      countryCode: $countryCode
      createDate: $createDate
      customerNumber: $customerNumber
      employeeId: $employeeId
      employeeName: $employeeName
      ticketId: $ticketId
    ) {
      countryCode
      createDate
      customerLocale
      customerNumber
      employeeId
      employeeName
      messurement
      ticketId
      ticketStatus
    }
  }
`;
export const cancelOrder = /* GraphQL */ `
  mutation CancelOrder(
    $countryCode: String!
    $orderNumber: String!
    $reason: CancelOrderReason!
    $warehouseId: String!
  ) {
    cancelOrder(
      countryCode: $countryCode
      orderNumber: $orderNumber
      reason: $reason
      warehouseId: $warehouseId
    )
  }
`;
export const changeBannerStatus = /* GraphQL */ `
  mutation ChangeBannerStatus(
    $active: Boolean!
    $countryCode: String!
    $id: String!
    $warehouseId: String!
  ) {
    changeBannerStatus(
      active: $active
      countryCode: $countryCode
      id: $id
      warehouseId: $warehouseId
    ) {
      active
      appDestination
      countryCode
      id
      locales
      parameter
      warehouseId
    }
  }
`;
export const closeConversation = /* GraphQL */ `
  mutation CloseConversation(
    $conversationId: String!
    $countryCode: String!
    $customerNumber: String!
    $measures: String!
  ) {
    closeConversation(
      conversationId: $conversationId
      countryCode: $countryCode
      customerNumber: $customerNumber
      measures: $measures
    ) {
      aladdinUnreadMessages
      conversationStatus
      countryCode
      createDate
      customerLocale
      customerMobile
      customerNumber
      customerUnreadMessages
      employeeId
      employeeName
      lastMessageDate
      measures
      source
    }
  }
`;
export const closeTicket = /* GraphQL */ `
  mutation CloseTicket(
    $countryCode: String!
    $createDate: String!
    $customerNumber: String!
    $employeeId: String!
    $employeeName: String!
    $messurement: String
    $ticketId: String
  ) {
    closeTicket(
      countryCode: $countryCode
      createDate: $createDate
      customerNumber: $customerNumber
      employeeId: $employeeId
      employeeName: $employeeName
      messurement: $messurement
      ticketId: $ticketId
    ) {
      countryCode
      createDate
      customerLocale
      customerNumber
      employeeId
      employeeName
      messurement
      ticketId
      ticketStatus
    }
  }
`;
export const confirmOrder = /* GraphQL */ `
  mutation ConfirmOrder($orderNumber: String!) {
    confirmOrder(orderNumber: $orderNumber)
  }
`;
export const correctQuantity = /* GraphQL */ `
  mutation CorrectQuantity(
    $barcode: String!
    $countryCode: String!
    $preOrderedProduct: Boolean
    $quantity: Int
    $warehouseId: String
  ) {
    correctQuantity(
      barcode: $barcode
      countryCode: $countryCode
      preOrderedProduct: $preOrderedProduct
      quantity: $quantity
      warehouseId: $warehouseId
    )
  }
`;
export const createAndSendInovoice = /* GraphQL */ `
  mutation CreateAndSendInovoice($orderNumber: String!) {
    createAndSendInovoice(orderNumber: $orderNumber) {
      invoice
      invoiceNumber
    }
  }
`;
export const createBanner = /* GraphQL */ `
  mutation CreateBanner(
    $appDestination: AppDestination
    $countryCode: String!
    $parameter: String
    $warehouseId: String!
  ) {
    createBanner(
      appDestination: $appDestination
      countryCode: $countryCode
      parameter: $parameter
      warehouseId: $warehouseId
    ) {
      active
      appDestination
      countryCode
      id
      locales
      parameter
      warehouseId
    }
  }
`;
export const createBraintreeClientToken = /* GraphQL */ `
  mutation CreateBraintreeClientToken($customerNumber: String!) {
    createBraintreeClientToken(customerNumber: $customerNumber)
  }
`;
export const createBraintreeTransaction = /* GraphQL */ `
  mutation CreateBraintreeTransaction(
    $amount: Float!
    $deviceData: String
    $paymentMethodNonce: String!
  ) {
    createBraintreeTransaction(
      amount: $amount
      deviceData: $deviceData
      paymentMethodNonce: $paymentMethodNonce
    ) {
      orderNumber
      statusCode
      success
      transactionReference
    }
  }
`;
export const createBrand = /* GraphQL */ `
  mutation CreateBrand($input: CreateBrandInput!) {
    createBrand(input: $input) {
      id
      locales
      name
      translations {
        locale
        translation
      }
    }
  }
`;
export const createCarrierShipmentOrder = /* GraphQL */ `
  mutation CreateCarrierShipmentOrder($input: CreateCarrierShipmentOrderInput) {
    createCarrierShipmentOrder(input: $input) {
      labelUrl
      shipmentNumber
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory($input: CreateCategoryInput!) {
    createCategory(input: $input) {
      description
      descriptionTranslations {
        locale
        translation
      }
      locales
      name
      nextChildSKU
      parent {
        description
        descriptionTranslations {
          locale
          translation
        }
        locales
        name
        nextChildSKU
        parent {
          description
          locales
          name
          nextChildSKU
          showChildren
          sku
          thumbnail
        }
        showChildren
        sku
        thumbnail
        translations {
          locale
          translation
        }
      }
      sku
      translations {
        locale
        translation
      }
    }
  }
`;
export const createConversation = /* GraphQL */ `
  mutation CreateConversation(
    $countryCode: String!
    $customerLocale: String!
    $customerNumber: String!
    $source: ConversationSource
  ) {
    createConversation(
      countryCode: $countryCode
      customerLocale: $customerLocale
      customerNumber: $customerNumber
      source: $source
    ) {
      aladdinUnreadMessages
      conversationStatus
      countryCode
      createDate
      customerLocale
      customerMobile
      customerNumber
      customerUnreadMessages
      employeeId
      employeeName
      lastMessageDate
      measures
      source
    }
  }
`;
export const createCountry = /* GraphQL */ `
  mutation CreateCountry($input: CreateCountryInput!) {
    createCountry(input: $input) {
      VATs
      countryCode
      locales
      managerId
      managerName
      name
      paymentType
      shipmentCosts {
        cost
        threshold
      }
      translations {
        locale
        translation
      }
    }
  }
`;
export const createCoupon = /* GraphQL */ `
  mutation CreateCoupon($input: CreateCouponInput!) {
    createCoupon(input: $input) {
      code
      countryCode
      couponTarget
      couponType
      createDate
      customerNumber
      customers
      description
      endDate
      isActive
      name
      orderTotalLimit
      orders
      ordersCount
      reusable
      sku
      value
      warehouseId
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer($input: CreateCustomerInput!) {
    createCustomer(input: $input) {
      billingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      countryCode
      credits
      customerNumber
      discounts
      email
      firstName
      friendshipCode
      hasOrders
      hasPendingPayment
      isMyFriendCodeUsed
      lastName
      lastOrderDate
      locale
      mobileNumber
      myFriendCode
      ordersCount
      preferredShippingType
      recommendations
      shippingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      subscribedToMarketingCall
      subscribedToMarketingMessage
      trackOrder
      trust
      username
    }
  }
`;
export const createDiscount = /* GraphQL */ `
  mutation CreateDiscount(
    $countryCode: String!
    $discount: Float
    $discountType: DiscountType
    $employeeId: String!
    $employeeName: String!
    $endDate: String
    $finalPrice: Float
    $locales: [String]
    $sku: String!
    $startDate: String
    $warehouseId: String!
  ) {
    createDiscount(
      countryCode: $countryCode
      discount: $discount
      discountType: $discountType
      employeeId: $employeeId
      employeeName: $employeeName
      endDate: $endDate
      finalPrice: $finalPrice
      locales: $locales
      sku: $sku
      startDate: $startDate
      warehouseId: $warehouseId
    ) {
      discount
      discountType
      employeeId
      employeeName
      endDate
      finalListPrice
      finalNetPrice
      isActive
      listPrice
      locales
      netPrice
      sku
      startDate
      warehouseId
    }
  }
`;
export const createEmployee = /* GraphQL */ `
  mutation CreateEmployee($input: CreateEmployeeInput!) {
    createEmployee(input: $input) {
      address
      countryCode
      employeeName
      groups
      locale
      telephone
      username
      warehouseId
      warehouseName
    }
  }
`;
export const createInovoice = /* GraphQL */ `
  mutation CreateInovoice(
    $countryCode: String!
    $orderNumber: String!
    $warehouseId: String!
  ) {
    createInovoice(
      countryCode: $countryCode
      orderNumber: $orderNumber
      warehouseId: $warehouseId
    ) {
      invoice
      invoiceNumber
    }
  }
`;
export const createInventoryEntry = /* GraphQL */ `
  mutation CreateInventoryEntry($input: CreateInventoryEntryInput!) {
    createInventoryEntry(input: $input)
  }
`;
export const createItem = /* GraphQL */ `
  mutation CreateItem($input: CreateItemInput!) {
    createItem(input: $input) {
      VATs
      VATsValue
      ageGroup
      availableQuantity
      barcode
      brandId
      brandName
      classification
      colorName
      cost
      createdAt
      description
      details
      discount
      discountType
      expires
      finalNetPrice
      finalPrice
      finalVATValue
      gender
      googleCategory
      ignoreRecurring
      images
      inventoryEntries
      listPrice
      locales
      maxInCart
      name
      netPrice
      offerEndDate
      offerId
      pricingBaseMeasreValue
      pricingBaseMeasureUnit
      pricingMeasreValue
      providerId
      quantity
      relatedItems
      size
      sku
      thumbnail
      warehouseId
    }
  }
`;
export const createKlarnaOrder = /* GraphQL */ `
  mutation CreateKlarnaOrder(
    $authorizationToken: String!
    $orderDetails: AWSJSON
  ) {
    createKlarnaOrder(
      authorizationToken: $authorizationToken
      orderDetails: $orderDetails
    ) {
      fraudStatus
      klarnaOrderId
      redirectUrl
    }
  }
`;
export const createKlarnaSession = /* GraphQL */ `
  mutation CreateKlarnaSession($orderDetails: AWSJSON) {
    createKlarnaSession(orderDetails: $orderDetails) {
      clientToken
      orderNumber
      paymentMethodCategories {
        identifier
        name
      }
      sessionId
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $employeeId: String
    $employeeName: String
    $message: String!
    $sender: Sender!
    $ticketId: String!
    $type: MessageType!
  ) {
    createMessage(
      employeeId: $employeeId
      employeeName: $employeeName
      message: $message
      sender: $sender
      ticketId: $ticketId
      type: $type
    ) {
      employeeId
      employeeName
      message
      sender
      sentDate
      ticketId
      type
    }
  }
`;
export const createOccasion = /* GraphQL */ `
  mutation CreateOccasion($input: OccasionInput!) {
    createOccasion(input: $input) {
      createDate
      image
      imageTranslations {
        locale
        translation
      }
      isActive
      items
      lastActivationDate
      name
      nameTranslations {
        locale
        translation
      }
      warehouseId
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder($order: OrderInput) {
    createOrder(order: $order) {
      VATValues {
        category
        value
      }
      aladdinPaymentStatus
      billingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      cancelReason
      countryCode
      couponCode
      couponName
      couponValue
      creditsPromotion
      customerFirstOrder
      customerLocale
      customerNumber
      dealsValue
      invoice
      invoiceNumber
      ipAddress
      items {
        VAT
        VATValue
        barcode
        cost
        discount
        discountRate
        finalNetPrice
        finalPrice
        finalVatValue
        listPrice
        name
        netPrice
        offerId
        orderDate
        orderNumber
        quantity
        sku
      }
      itemsTotal
      loyalityProgramCredits
      mobileNumber
      orderDate
      orderNumber
      orderStatus
      orderTotal
      packets
      paymentType
      shipmentType
      shippingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      shippingCost
      shippingDiscount
      shippingVatValue
      statuses {
        date
        employeeId
        employeeName
        orderNumber
        status
      }
      subtotal
      transactionReference
      verifyCustomer
      warehouseId
    }
  }
`;
export const createPaymentWarning = /* GraphQL */ `
  mutation CreatePaymentWarning(
    $countryCode: String
    $orderNumber: String!
    $warehouseId: String
    $warningNumber: Int
  ) {
    createPaymentWarning(
      countryCode: $countryCode
      orderNumber: $orderNumber
      warehouseId: $warehouseId
      warningNumber: $warningNumber
    ) {
      orderNumber
      warningDate
      warningNumber
      warningPath
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct($input: CreateProductInput!) {
    createProduct(input: $input) {
      ageGroup
      attributes
      attributesTranslations {
        locale
        translation
      }
      barcode
      brand {
        id
        locales
        name
        translations {
          locale
          translation
        }
      }
      colorName
      colorNameTranslations {
        locale
        translation
      }
      description
      descriptionTranslations {
        locale
        translation
      }
      details
      detailsTranslations {
        locale
        translation
      }
      gender
      googleCategory
      images
      locales
      name
      nameTranslations {
        locale
        translation
      }
      nextImageId
      parent {
        description
        descriptionTranslations {
          locale
          translation
        }
        locales
        name
        nextChildSKU
        parent {
          description
          locales
          name
          nextChildSKU
          showChildren
          sku
          thumbnail
        }
        sku
        translations {
          locale
          translation
        }
      }
      pricingBaseMeasreValue
      pricingBaseMeasureUnit
      pricingMeasreValue
      relatedProducts
      size
      sizeTranslations {
        locale
        translation
      }
      sku
      thumbnail
    }
  }
`;
export const createProvider = /* GraphQL */ `
  mutation CreateProvider($input: CreateProviderInput!) {
    createProvider(input: $input) {
      address
      contactName
      id
      providerName
      telephone
      website
    }
  }
`;
export const createSection = /* GraphQL */ `
  mutation CreateSection($input: CreateSectionInput!) {
    createSection(input: $input) {
      description
      descriptionTranslations {
        locale
        translation
      }
      locales
      name
      nextChildSKU
      parent {
        description
        descriptionTranslations {
          locale
          translation
        }
        locales
        name
        nextChildSKU
        parent {
          description
          locales
          name
          nextChildSKU
          showChildren
          sku
          thumbnail
        }
        showChildren
        sku
        thumbnail
        translations {
          locale
          translation
        }
      }
      showChildren
      sku
      thumbnail
      translations {
        locale
        translation
      }
    }
  }
`;
export const createTicket = /* GraphQL */ `
  mutation CreateTicket(
    $countryCode: String!
    $customerLocale: String
    $customerNumber: String!
    $employeeId: String
    $employeeName: String
  ) {
    createTicket(
      countryCode: $countryCode
      customerLocale: $customerLocale
      customerNumber: $customerNumber
      employeeId: $employeeId
      employeeName: $employeeName
    ) {
      countryCode
      createDate
      customerLocale
      customerNumber
      employeeId
      employeeName
      messurement
      ticketId
      ticketStatus
    }
  }
`;
export const createWarehouse = /* GraphQL */ `
  mutation CreateWarehouse($input: CreateWarehouseInput!) {
    createWarehouse(input: $input) {
      address
      countryCode
      id
      managerId
      managerName
      name
    }
  }
`;
export const deactivateDiscount = /* GraphQL */ `
  mutation DeactivateDiscount(
    $countryCode: String!
    $offerId: String!
    $sku: String!
    $warehouseId: String!
  ) {
    deactivateDiscount(
      countryCode: $countryCode
      offerId: $offerId
      sku: $sku
      warehouseId: $warehouseId
    )
  }
`;
export const editItemPrice = /* GraphQL */ `
  mutation EditItemPrice(
    $VATs: Float!
    $VATsValue: Float!
    $listPrice: Float!
    $netPrice: Float!
    $sku: String!
    $warehouseId: String!
  ) {
    editItemPrice(
      VATs: $VATs
      VATsValue: $VATsValue
      listPrice: $listPrice
      netPrice: $netPrice
      sku: $sku
      warehouseId: $warehouseId
    )
  }
`;
export const ignoreRecurring = /* GraphQL */ `
  mutation IgnoreRecurring(
    $sku: String!
    $toIgnore: Boolean!
    $warehouseId: String!
  ) {
    ignoreRecurring(sku: $sku, toIgnore: $toIgnore, warehouseId: $warehouseId)
  }
`;
export const makePaymentViaAdyen = /* GraphQL */ `
  mutation MakePaymentViaAdyen(
    $locale: String!
    $orderDetails: OrderInput
    $paymentComponentData: AWSJSON!
    $returnUrl: String!
  ) {
    makePaymentViaAdyen(
      locale: $locale
      orderDetails: $orderDetails
      paymentComponentData: $paymentComponentData
      returnUrl: $returnUrl
    )
  }
`;
export const moveCartItems = /* GraphQL */ `
  mutation MoveCartItems($fromCustomer: String!, $toCustomer: String!) {
    moveCartItems(fromCustomer: $fromCustomer, toCustomer: $toCustomer)
  }
`;
export const moveItemToShelf = /* GraphQL */ `
  mutation MoveItemToShelf($input: MoveItemToShelfInput!) {
    moveItemToShelf(input: $input)
  }
`;
export const openConversation = /* GraphQL */ `
  mutation OpenConversation(
    $conversationId: String!
    $countryCode: String!
    $customerNumber: String!
  ) {
    openConversation(
      conversationId: $conversationId
      countryCode: $countryCode
      customerNumber: $customerNumber
    ) {
      aladdinUnreadMessages
      conversationStatus
      countryCode
      createDate
      customerLocale
      customerMobile
      customerNumber
      customerUnreadMessages
      employeeId
      employeeName
      lastMessageDate
      measures
      source
    }
  }
`;
export const postConversationMessage = /* GraphQL */ `
  mutation PostConversationMessage(
    $conversationId: String!
    $countryCode: String!
    $customerNumber: String!
    $employeeId: String
    $employeeName: String
    $message: String!
    $sender: Sender!
    $type: MessageType!
  ) {
    postConversationMessage(
      conversationId: $conversationId
      countryCode: $countryCode
      customerNumber: $customerNumber
      employeeId: $employeeId
      employeeName: $employeeName
      message: $message
      sender: $sender
      type: $type
    ) {
      conversationId
      countryCode
      customerNumber
      employeeId
      employeeName
      message
      read
      sender
      sentDate
      type
    }
  }
`;
export const refreshCartItems = /* GraphQL */ `
  mutation RefreshCartItems($customerNumber: String!) {
    refreshCartItems(customerNumber: $customerNumber)
  }
`;
export const removeItemFromCart = /* GraphQL */ `
  mutation RemoveItemFromCart(
    $customerNumber: String!
    $quantity: Int!
    $quantityDiff: Int!
    $sku: String!
    $warehouseId: String!
  ) {
    removeItemFromCart(
      customerNumber: $customerNumber
      quantity: $quantity
      quantityDiff: $quantityDiff
      sku: $sku
      warehouseId: $warehouseId
    ) {
      quantity
      sku
      warehouseId
    }
  }
`;
export const removeItemFromCustomerCart = /* GraphQL */ `
  mutation RemoveItemFromCustomerCart(
    $customerNumber: String!
    $quantity: Int!
    $sku: String!
    $warehouseId: String!
  ) {
    removeItemFromCustomerCart(
      customerNumber: $customerNumber
      quantity: $quantity
      sku: $sku
      warehouseId: $warehouseId
    )
  }
`;
export const removeItemFromFavorite = /* GraphQL */ `
  mutation RemoveItemFromFavorite($customerNumber: String!, $sku: String!) {
    removeItemFromFavorite(customerNumber: $customerNumber, sku: $sku)
  }
`;
export const removeProductImage = /* GraphQL */ `
  mutation RemoveProductImage(
    $image: String!
    $sku: String!
    $userLocale: String!
  ) {
    removeProductImage(image: $image, sku: $sku, userLocale: $userLocale)
  }
`;
export const sendPersonalizedDiscountsEmail = /* GraphQL */ `
  mutation SendPersonalizedDiscountsEmail(
    $countryCode: String!
    $customerHasOrders: Boolean
    $discountTypes: [DiscountType]
    $warehouseId: String!
  ) {
    sendPersonalizedDiscountsEmail(
      countryCode: $countryCode
      customerHasOrders: $customerHasOrders
      discountTypes: $discountTypes
      warehouseId: $warehouseId
    )
  }
`;
export const sendQuestionareEmail = /* GraphQL */ `
  mutation SendQuestionareEmail {
    sendQuestionareEmail
  }
`;
export const sendShipmentConfirmation = /* GraphQL */ `
  mutation SendShipmentConfirmation($orderNumber: String!) {
    sendShipmentConfirmation(orderNumber: $orderNumber)
  }
`;
export const sendVerificationCode = /* GraphQL */ `
  mutation SendVerificationCode(
    $customerNumber: String!
    $destination: String!
    $medium: CommunicationMedium!
  ) {
    sendVerificationCode(
      customerNumber: $customerNumber
      destination: $destination
      medium: $medium
    )
  }
`;
export const setAladdinInvoicePaid = /* GraphQL */ `
  mutation SetAladdinInvoicePaid($orderNumber: String) {
    setAladdinInvoicePaid(orderNumber: $orderNumber)
  }
`;
export const setConversationMessageRead = /* GraphQL */ `
  mutation SetConversationMessageRead(
    $conversationId: String!
    $countryCode: String!
    $customerNumber: String!
    $originalSender: Sender!
    $sendDate: String!
  ) {
    setConversationMessageRead(
      conversationId: $conversationId
      countryCode: $countryCode
      customerNumber: $customerNumber
      originalSender: $originalSender
      sendDate: $sendDate
    ) {
      conversationId
      countryCode
      customerNumber
      employeeId
      employeeName
      message
      read
      sender
      sentDate
      type
    }
  }
`;
export const setCustomerTrusted = /* GraphQL */ `
  mutation SetCustomerTrusted($customerNumber: String!, $trust: Int!) {
    setCustomerTrusted(customerNumber: $customerNumber, trust: $trust)
  }
`;
export const setCustomerVerified = /* GraphQL */ `
  mutation SetCustomerVerified(
    $countryCode: String!
    $orderNumber: String!
    $warehouseId: String!
  ) {
    setCustomerVerified(
      countryCode: $countryCode
      orderNumber: $orderNumber
      warehouseId: $warehouseId
    )
  }
`;
export const setMaxInCart = /* GraphQL */ `
  mutation SetMaxInCart($maxInCart: Int, $sku: String!, $warehouseId: String!) {
    setMaxInCart(maxInCart: $maxInCart, sku: $sku, warehouseId: $warehouseId)
  }
`;
export const setOccasionIsActive = /* GraphQL */ `
  mutation SetOccasionIsActive(
    $id: String!
    $isActive: Boolean!
    $warehouseId: String!
  ) {
    setOccasionIsActive(id: $id, isActive: $isActive, warehouseId: $warehouseId)
  }
`;
export const setOrderBeingCollected = /* GraphQL */ `
  mutation SetOrderBeingCollected(
    $countryCode: String!
    $employeeName: String
    $orderNumber: String!
    $username: String
    $warehouseId: String!
  ) {
    setOrderBeingCollected(
      countryCode: $countryCode
      employeeName: $employeeName
      orderNumber: $orderNumber
      username: $username
      warehouseId: $warehouseId
    ) {
      VATValues {
        category
        value
      }
      aladdinPaymentStatus
      billingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      cancelReason
      countryCode
      couponCode
      couponName
      couponValue
      creditsPromotion
      customerFirstOrder
      customerLocale
      customerNumber
      dealsValue
      invoice
      invoiceNumber
      ipAddress
      items {
        VAT
        VATValue
        barcode
        cost
        discount
        discountRate
        finalNetPrice
        finalPrice
        finalVatValue
        listPrice
        name
        netPrice
        offerId
        orderDate
        orderNumber
        quantity
        sku
      }
      itemsTotal
      loyalityProgramCredits
      mobileNumber
      orderDate
      orderNumber
      orderStatus
      orderTotal
      packets
      paymentType
      shipmentType
      shippingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      shippingCost
      shippingDiscount
      shippingVatValue
      statuses {
        date
        employeeId
        employeeName
        orderNumber
        status
      }
      subtotal
      transactionReference
      verifyCustomer
      warehouseId
    }
  }
`;
export const setOrderCollected = /* GraphQL */ `
  mutation SetOrderCollected(
    $countryCode: String!
    $employeeName: String
    $orderNumber: String!
    $username: String
    $warehouseId: String!
  ) {
    setOrderCollected(
      countryCode: $countryCode
      employeeName: $employeeName
      orderNumber: $orderNumber
      username: $username
      warehouseId: $warehouseId
    ) {
      VATValues {
        category
        value
      }
      aladdinPaymentStatus
      billingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      cancelReason
      countryCode
      couponCode
      couponName
      couponValue
      creditsPromotion
      customerFirstOrder
      customerLocale
      customerNumber
      dealsValue
      invoice
      invoiceNumber
      ipAddress
      items {
        VAT
        VATValue
        barcode
        cost
        discount
        discountRate
        finalNetPrice
        finalPrice
        finalVatValue
        listPrice
        name
        netPrice
        offerId
        orderDate
        orderNumber
        quantity
        sku
      }
      itemsTotal
      loyalityProgramCredits
      mobileNumber
      orderDate
      orderNumber
      orderStatus
      orderTotal
      packets
      paymentType
      shipmentType
      shippingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      shippingCost
      shippingDiscount
      shippingVatValue
      statuses {
        date
        employeeId
        employeeName
        orderNumber
        status
      }
      subtotal
      transactionReference
      verifyCustomer
      warehouseId
    }
  }
`;
export const setOrderShipped = /* GraphQL */ `
  mutation SetOrderShipped(
    $countryCode: String!
    $employeeName: String
    $orderNumber: String!
    $username: String
    $warehouseId: String!
  ) {
    setOrderShipped(
      countryCode: $countryCode
      employeeName: $employeeName
      orderNumber: $orderNumber
      username: $username
      warehouseId: $warehouseId
    ) {
      VATValues {
        category
        value
      }
      aladdinPaymentStatus
      billingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      cancelReason
      countryCode
      couponCode
      couponName
      couponValue
      creditsPromotion
      customerFirstOrder
      customerLocale
      customerNumber
      dealsValue
      invoice
      invoiceNumber
      ipAddress
      items {
        VAT
        VATValue
        barcode
        cost
        discount
        discountRate
        finalNetPrice
        finalPrice
        finalVatValue
        listPrice
        name
        netPrice
        offerId
        orderDate
        orderNumber
        quantity
        sku
      }
      itemsTotal
      loyalityProgramCredits
      mobileNumber
      orderDate
      orderNumber
      orderStatus
      orderTotal
      packets
      paymentType
      shipmentType
      shippingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      shippingCost
      shippingDiscount
      shippingVatValue
      statuses {
        date
        employeeId
        employeeName
        orderNumber
        status
      }
      subtotal
      transactionReference
      verifyCustomer
      warehouseId
    }
  }
`;
export const subscribeToNotification = /* GraphQL */ `
  mutation SubscribeToNotification(
    $customerNumber: String!
    $notificationTypes: [NotificationType]!
  ) {
    subscribeToNotification(
      customerNumber: $customerNumber
      notificationTypes: $notificationTypes
    ) {
      billingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      countryCode
      credits
      customerNumber
      discounts
      email
      firstName
      friendshipCode
      hasOrders
      hasPendingPayment
      isMyFriendCodeUsed
      lastName
      lastOrderDate
      locale
      mobileNumber
      myFriendCode
      ordersCount
      preferredShippingType
      recommendations
      shippingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      subscribedToMarketingCall
      subscribedToMarketingMessage
      trackOrder
      trust
      username
    }
  }
`;
export const unsubscribeToNotification = /* GraphQL */ `
  mutation UnsubscribeToNotification(
    $customerNumber: String!
    $notificationTypes: [NotificationType]!
  ) {
    unsubscribeToNotification(
      customerNumber: $customerNumber
      notificationTypes: $notificationTypes
    ) {
      billingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      countryCode
      credits
      customerNumber
      discounts
      email
      firstName
      friendshipCode
      hasOrders
      hasPendingPayment
      isMyFriendCodeUsed
      lastName
      lastOrderDate
      locale
      mobileNumber
      myFriendCode
      ordersCount
      preferredShippingType
      recommendations
      shippingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      subscribedToMarketingCall
      subscribedToMarketingMessage
      trackOrder
      trust
      username
    }
  }
`;
export const updateBillingAddress = /* GraphQL */ `
  mutation UpdateBillingAddress(
    $address: AddressInput!
    $customerNumber: String!
    $username: String!
  ) {
    updateBillingAddress(
      address: $address
      customerNumber: $customerNumber
      username: $username
    ) {
      billingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      countryCode
      credits
      customerNumber
      discounts
      email
      firstName
      friendshipCode
      hasOrders
      hasPendingPayment
      isMyFriendCodeUsed
      lastName
      lastOrderDate
      locale
      mobileNumber
      myFriendCode
      ordersCount
      preferredShippingType
      recommendations
      shippingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      subscribedToMarketingCall
      subscribedToMarketingMessage
      trackOrder
      trust
      username
    }
  }
`;
export const updateBrandTranslation = /* GraphQL */ `
  mutation UpdateBrandTranslation(
    $id: String!
    $locale: String!
    $name: String!
    $userLocale: String!
  ) {
    updateBrandTranslation(
      id: $id
      locale: $locale
      name: $name
      userLocale: $userLocale
    ) {
      id
      locales
      name
      translations {
        locale
        translation
      }
    }
  }
`;
export const updateCategoryDescription = /* GraphQL */ `
  mutation UpdateCategoryDescription(
    $description: String!
    $locale: String!
    $sku: String!
    $userLocale: String!
  ) {
    updateCategoryDescription(
      description: $description
      locale: $locale
      sku: $sku
      userLocale: $userLocale
    ) {
      description
      descriptionTranslations {
        locale
        translation
      }
      locales
      name
      nextChildSKU
      parent {
        description
        descriptionTranslations {
          locale
          translation
        }
        locales
        name
        nextChildSKU
        parent {
          description
          locales
          name
          nextChildSKU
          showChildren
          sku
          thumbnail
        }
        showChildren
        sku
        thumbnail
        translations {
          locale
          translation
        }
      }
      sku
      translations {
        locale
        translation
      }
    }
  }
`;
export const updateCategoryTranslation = /* GraphQL */ `
  mutation UpdateCategoryTranslation(
    $locale: String!
    $name: String!
    $sku: String!
    $userLocale: String!
  ) {
    updateCategoryTranslation(
      locale: $locale
      name: $name
      sku: $sku
      userLocale: $userLocale
    ) {
      description
      descriptionTranslations {
        locale
        translation
      }
      locales
      name
      nextChildSKU
      parent {
        description
        descriptionTranslations {
          locale
          translation
        }
        locales
        name
        nextChildSKU
        parent {
          description
          locales
          name
          nextChildSKU
          showChildren
          sku
          thumbnail
        }
        showChildren
        sku
        thumbnail
        translations {
          locale
          translation
        }
      }
      sku
      translations {
        locale
        translation
      }
    }
  }
`;
export const updateCountryTranslation = /* GraphQL */ `
  mutation UpdateCountryTranslation(
    $countryCode: String!
    $locale: String!
    $name: String!
    $userLocale: String!
  ) {
    updateCountryTranslation(
      countryCode: $countryCode
      locale: $locale
      name: $name
      userLocale: $userLocale
    ) {
      VATs
      countryCode
      locales
      managerId
      managerName
      name
      paymentType
      shipmentCosts {
        cost
        threshold
      }
      translations {
        locale
        translation
      }
    }
  }
`;
export const updateCustomerEmail = /* GraphQL */ `
  mutation UpdateCustomerEmail($customerNumber: String!, $email: String!) {
    updateCustomerEmail(customerNumber: $customerNumber, email: $email) {
      billingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      countryCode
      credits
      customerNumber
      discounts
      email
      firstName
      friendshipCode
      hasOrders
      hasPendingPayment
      isMyFriendCodeUsed
      lastName
      lastOrderDate
      locale
      mobileNumber
      myFriendCode
      ordersCount
      preferredShippingType
      recommendations
      shippingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      subscribedToMarketingCall
      subscribedToMarketingMessage
      trackOrder
      trust
      username
    }
  }
`;
export const updateCustomerMobile = /* GraphQL */ `
  mutation UpdateCustomerMobile(
    $customerNumber: String!
    $mobileNumber: String!
  ) {
    updateCustomerMobile(
      customerNumber: $customerNumber
      mobileNumber: $mobileNumber
    ) {
      billingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      countryCode
      credits
      customerNumber
      discounts
      email
      firstName
      friendshipCode
      hasOrders
      hasPendingPayment
      isMyFriendCodeUsed
      lastName
      lastOrderDate
      locale
      mobileNumber
      myFriendCode
      ordersCount
      preferredShippingType
      recommendations
      shippingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      subscribedToMarketingCall
      subscribedToMarketingMessage
      trackOrder
      trust
      username
    }
  }
`;
export const updateDeviceToken = /* GraphQL */ `
  mutation UpdateDeviceToken($customerNumber: String!, $token: String!) {
    updateDeviceToken(customerNumber: $customerNumber, token: $token)
  }
`;
export const updateExistingCustomersCount = /* GraphQL */ `
  mutation UpdateExistingCustomersCount(
    $countryCode: String!
    $customersCount: Int!
    $warehouseId: String!
  ) {
    updateExistingCustomersCount(
      countryCode: $countryCode
      customersCount: $customersCount
      warehouseId: $warehouseId
    )
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct($input: UpdateProductInput!) {
    updateProduct(input: $input)
  }
`;
export const updateProductBarcode = /* GraphQL */ `
  mutation UpdateProductBarcode($barcode: String, $sku: String!) {
    updateProductBarcode(barcode: $barcode, sku: $sku)
  }
`;
export const updateProductThumbnail = /* GraphQL */ `
  mutation UpdateProductThumbnail(
    $sku: String!
    $thumbnail: String!
    $userLocale: String!
  ) {
    updateProductThumbnail(
      sku: $sku
      thumbnail: $thumbnail
      userLocale: $userLocale
    )
  }
`;
export const updateProductTranslation = /* GraphQL */ `
  mutation UpdateProductTranslation(
    $locale: String!
    $name: String!
    $sku: String!
    $userLocale: String!
  ) {
    updateProductTranslation(
      locale: $locale
      name: $name
      sku: $sku
      userLocale: $userLocale
    ) {
      ageGroup
      attributes
      attributesTranslations {
        locale
        translation
      }
      barcode
      brand {
        id
        locales
        name
        translations {
          locale
          translation
        }
      }
      colorName
      colorNameTranslations {
        locale
        translation
      }
      description
      descriptionTranslations {
        locale
        translation
      }
      details
      detailsTranslations {
        locale
        translation
      }
      gender
      googleCategory
      images
      locales
      name
      nameTranslations {
        locale
        translation
      }
      nextImageId
      parent {
        description
        descriptionTranslations {
          locale
          translation
        }
        locales
        name
        nextChildSKU
        parent {
          description
          locales
          name
          nextChildSKU
          showChildren
          sku
          thumbnail
        }
        sku
        translations {
          locale
          translation
        }
      }
      pricingBaseMeasreValue
      pricingBaseMeasureUnit
      pricingMeasreValue
      relatedProducts
      size
      sizeTranslations {
        locale
        translation
      }
      sku
      thumbnail
    }
  }
`;
export const updateRelatedProducts = /* GraphQL */ `
  mutation UpdateRelatedProducts(
    $relatedProducts: [String]!
    $sku: String!
    $userLocale: String!
  ) {
    updateRelatedProducts(
      relatedProducts: $relatedProducts
      sku: $sku
      userLocale: $userLocale
    ) {
      ageGroup
      attributes
      attributesTranslations {
        locale
        translation
      }
      barcode
      brand {
        id
        locales
        name
        translations {
          locale
          translation
        }
      }
      colorName
      colorNameTranslations {
        locale
        translation
      }
      description
      descriptionTranslations {
        locale
        translation
      }
      details
      detailsTranslations {
        locale
        translation
      }
      gender
      googleCategory
      images
      locales
      name
      nameTranslations {
        locale
        translation
      }
      nextImageId
      parent {
        description
        descriptionTranslations {
          locale
          translation
        }
        locales
        name
        nextChildSKU
        parent {
          description
          locales
          name
          nextChildSKU
          showChildren
          sku
          thumbnail
        }
        sku
        translations {
          locale
          translation
        }
      }
      pricingBaseMeasreValue
      pricingBaseMeasureUnit
      pricingMeasreValue
      relatedProducts
      size
      sizeTranslations {
        locale
        translation
      }
      sku
      thumbnail
    }
  }
`;
export const updateSectionDescription = /* GraphQL */ `
  mutation UpdateSectionDescription(
    $description: String!
    $locale: String!
    $sku: String!
    $userLocale: String!
  ) {
    updateSectionDescription(
      description: $description
      locale: $locale
      sku: $sku
      userLocale: $userLocale
    ) {
      description
      descriptionTranslations {
        locale
        translation
      }
      locales
      name
      nextChildSKU
      parent {
        description
        descriptionTranslations {
          locale
          translation
        }
        locales
        name
        nextChildSKU
        parent {
          description
          locales
          name
          nextChildSKU
          showChildren
          sku
          thumbnail
        }
        showChildren
        sku
        thumbnail
        translations {
          locale
          translation
        }
      }
      showChildren
      sku
      thumbnail
      translations {
        locale
        translation
      }
    }
  }
`;
export const updateSectionThumbnail = /* GraphQL */ `
  mutation UpdateSectionThumbnail(
    $sku: String!
    $thumbnail: String!
    $userLocale: String!
  ) {
    updateSectionThumbnail(
      sku: $sku
      thumbnail: $thumbnail
      userLocale: $userLocale
    ) {
      description
      descriptionTranslations {
        locale
        translation
      }
      locales
      name
      nextChildSKU
      parent {
        description
        descriptionTranslations {
          locale
          translation
        }
        locales
        name
        nextChildSKU
        parent {
          description
          locales
          name
          nextChildSKU
          showChildren
          sku
          thumbnail
        }
        showChildren
        sku
        thumbnail
        translations {
          locale
          translation
        }
      }
      showChildren
      sku
      thumbnail
      translations {
        locale
        translation
      }
    }
  }
`;
export const updateSectionTranslation = /* GraphQL */ `
  mutation UpdateSectionTranslation(
    $locale: String!
    $name: String!
    $sku: String!
    $userLocale: String!
  ) {
    updateSectionTranslation(
      locale: $locale
      name: $name
      sku: $sku
      userLocale: $userLocale
    ) {
      description
      descriptionTranslations {
        locale
        translation
      }
      locales
      name
      nextChildSKU
      parent {
        description
        descriptionTranslations {
          locale
          translation
        }
        locales
        name
        nextChildSKU
        parent {
          description
          locales
          name
          nextChildSKU
          showChildren
          sku
          thumbnail
        }
        showChildren
        sku
        thumbnail
        translations {
          locale
          translation
        }
      }
      showChildren
      sku
      thumbnail
      translations {
        locale
        translation
      }
    }
  }
`;
export const updateShippingAddress = /* GraphQL */ `
  mutation UpdateShippingAddress(
    $address: AddressInput!
    $customerNumber: String!
    $username: String!
  ) {
    updateShippingAddress(
      address: $address
      customerNumber: $customerNumber
      username: $username
    ) {
      billingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      countryCode
      credits
      customerNumber
      discounts
      email
      firstName
      friendshipCode
      hasOrders
      hasPendingPayment
      isMyFriendCodeUsed
      lastName
      lastOrderDate
      locale
      mobileNumber
      myFriendCode
      ordersCount
      preferredShippingType
      recommendations
      shippingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      subscribedToMarketingCall
      subscribedToMarketingMessage
      trackOrder
      trust
      username
    }
  }
`;
export const useYourFriendCode = /* GraphQL */ `
  mutation UseYourFriendCode {
    useYourFriendCode {
      createDate
      customers
    }
  }
`;
export const verifyContactDetails = /* GraphQL */ `
  mutation VerifyContactDetails(
    $code: String
    $customerNumber: String!
    $destination: String!
    $medium: CommunicationMedium!
  ) {
    verifyContactDetails(
      code: $code
      customerNumber: $customerNumber
      destination: $destination
      medium: $medium
    ) {
      billingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      countryCode
      credits
      customerNumber
      discounts
      email
      firstName
      friendshipCode
      hasOrders
      hasPendingPayment
      isMyFriendCodeUsed
      lastName
      lastOrderDate
      locale
      mobileNumber
      myFriendCode
      ordersCount
      preferredShippingType
      recommendations
      shippingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      subscribedToMarketingCall
      subscribedToMarketingMessage
      trackOrder
      trust
      username
    }
  }
`;
