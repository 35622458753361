import React, {
  useReducer,
  useEffect,
  useContext,
  Fragment,
  useState,
} from "react"
import * as serviceWorker from "../../serviceWorker"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import GlobalContext from "../../context/global-context"
import API, { graphqlOperation } from "@aws-amplify/api"

import { I18n } from "aws-amplify"
import ConversationsList from "./ConversationsList"
import {
  listConversationsByEmployee,
  listEmployees,
  listNewConversations,
} from "../../graphql/queries"
import { assignConversation, openConversation } from "../../graphql/mutations"
import conversationsReducer from "./conversations"
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core"
import ConversationOverview from "./ConversationOverview"
import {
  onAssignConversation,
  onCreateConversation,
} from "../../graphql/subscriptions"

const ConversationsPage = (props) => {
  const { currentUser } = useContext(GlobalContext)
  const [
    { newConversations, myNewConversations, myOpenConversations },
    dispatch,
  ] = useReducer(conversationsReducer, {
    newConversations: [],
    myNewConversations: [],
    myOpenConversations: [],
  })
  const [employees, setEmployees] = useState([])
  const [conversationToAssign, setConversationToAssign] = useState()

  const countryCode = currentUser.countryCode
  const employeeId = currentUser.username
  const employeeName = currentUser.employeeName
  const userRoles = new Set(currentUser.groups)
  const isCC = userRoles.has("CC")
  const isCCM = userRoles.has("CCM")

  let onCreateConversationSubscription
  let onAssignedConversationSubscription

  let subscribed = true

  useEffect(() => {
    if (isCC) {
      listMyConversations()
      subscribeToAssignConversation()
    }

    if (isCCM) {
      listCCEmployees()
      listAllNewConversations()
      subscribeToNewConversation()
    }

    return () => {
      subscribed = false
      if (onAssignedConversationSubscription)
        onAssignedConversationSubscription.unsubscribe()
      if (onCreateConversationSubscription)
        onCreateConversationSubscription.unsubscribe()
    }
  }, [])

  const subscribeToNewConversation = () => {
    onCreateConversationSubscription = API.graphql(
      graphqlOperation(onCreateConversation)
    ).subscribe({
      next: (evt) => {
        if (subscribed) {
          const conversation = evt.value.data.onCreateConversation
          dispatch({ type: "ADD_NEW_CONVERSATION", conversation })
        }
      },
      error: (error) => {
        console.log(error)
      },
    })
  }

  const subscribeToAssignConversation = () => {
    onAssignedConversationSubscription = API.graphql(
      graphqlOperation(onAssignConversation, {
        employeeId: currentUser.username,
      })
    ).subscribe({
      next: (evt) => {
        if (subscribed) {
          const conversation = evt.value.data.onAssignConversation
          console.log(conversation)
          dispatch({ type: "ASSIGN_CONVERSATION", conversation })
        }
      },
      error: (error) => {
        console.log(error)
      },
    })
  }

  const listMyConversations = () => {
    const args = {
      employeeId: currentUser.username,
    }
    API.graphql(graphqlOperation(listConversationsByEmployee, args))
      .then((response) => {
        if (subscribed)
          dispatch({
            type: "LOAD_MY_CONVERSATIONS",
            conversations: response.data.listConversationsByEmployee,
          })
      })
      .catch((error) => {
        console.log(error)
      })
  }
  const listAllNewConversations = () => {
    const args = {
      countryCode,
    }
    API.graphql(graphqlOperation(listNewConversations, args))
      .then((response) => {
        if (subscribed)
          dispatch({
            type: "LOAD_NEW_CONVERSATIONS",
            conversations: response.data.listNewConversations,
          })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const listCCEmployees = () => {
    API.graphql(graphqlOperation(listEmployees))
      .then((response) => {
        if (subscribed) {
          const employees = response.data.listEmployees.items
          setEmployees(employees)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleAssignConversation = (selectedEmployee) => {
    const args = {
      conversationId: conversationToAssign.createDate,
      customerNumber: conversationToAssign.customerNumber,
      countryCode: conversationToAssign.countryCode,
      employeeId: selectedEmployee.username,
      employeeName: selectedEmployee.employeeName,
    }
    API.graphql(graphqlOperation(assignConversation, args))
      .then((response) => {
        if (subscribed) {
          dispatch({
            type: "REMOVE_NEW_CONVERSATION",
            conversation: conversationToAssign,
          })
          setConversationToAssign()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  const handleClostDialog = () => {
    setConversationToAssign()
  }
  const openConversationOverview = (conversation) => {
    setConversationToAssign(conversation)
  }

  const handleOpenConversation = (conversation) => {
    if (conversation.conversationStatus === "ASSIGNED")
      setConversationOpen(conversation)
    // const stringifiedConversation = JSON.stringify(conversation)
    // const encodedConversation = btoa(stringifiedConversation)
    // props.history.push(`/open-conversation/${encodedConversation}`)
    props.history.push(
      `/open-conversation/${conversation.customerNumber}/${conversation.countryCode}/${conversation.createDate}`
    )
  }
  const setConversationOpen = (conversation) => {
    const args = {
      conversationId: conversation.createDate,
      customerNumber: conversation.customerNumber,
      countryCode: conversation.countryCode,
    }
    API.graphql(graphqlOperation(openConversation, args))
      .then((response) => {
        if (subscribed)
          dispatch({
            type: "SET_CONVERSATION_OPEN",
            conversation: response.data.openConversation,
          })
      })
      .catch((error) => console.log(error))
  }

  return (
    <Fragment>
      {isCCM && (
        <Paper style={{ marginTop: 20 }}>
          <Typography variant="body2" style={{ padding: 10 }}>
            {I18n.get("label_new_conversations")}
          </Typography>
          <ConversationsList
            conversations={newConversations}
            onClick={openConversationOverview}
          />
          <Dialog
            open={conversationToAssign != null}
            onClose={handleClostDialog}
            aria-labelledby="form-dialog-title"
            fullWidth
          >
            <DialogTitle id="form-dialog-title">
              {I18n.get("label_assign_conversation")}
            </DialogTitle>
            <DialogContent>
              {conversationToAssign && (
                <ConversationOverview
                  conversation={conversationToAssign}
                  employees={employees}
                  assingConversationHandler={handleAssignConversation}
                />
              )}
            </DialogContent>
          </Dialog>
        </Paper>
      )}
      {isCC && (
        <Paper style={{ marginTop: 20 }}>
          <Typography variant="body2" style={{ padding: 10 }}>
            {I18n.get("label_my_new_conversations")}
          </Typography>
          <ConversationsList
            conversations={myNewConversations}
            onClick={handleOpenConversation}
          />
        </Paper>
      )}
      {isCC && (
        <Paper style={{ marginTop: 20 }}>
          <Typography variant="body2" style={{ padding: 10 }}>
            {I18n.get("label_my_open_conversations")}
          </Typography>
          <ConversationsList
            conversations={myOpenConversations}
            onClick={handleOpenConversation}
          />
        </Paper>
      )}
    </Fragment>
  )
}

export default ConversationsPage
