const conversationMessagesReducer = (state, action) => {
  switch (action.type) {
    case "LOAD_MMESSAGES":
      return { ...state, messages: action.messages }
    case "ADD_NEW_MESSAGE":
      return { ...state, messages: [...state.messages, action.message] }
    case "ADD_NEW_MESSAGES":
      return { ...state, messages: [...state.messages, ...action.messages] }
    default:
      return state
  }
}
export default conversationMessagesReducer
