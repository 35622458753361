import React, { Fragment, useState } from "react"
import API, { graphqlOperation } from "@aws-amplify/api"
import { getBounceRate, listOrders } from "../../graphql/queries"
import {
  updateExistingCustomersCount,
  addOrderToStatistics,
} from "../../graphql/mutations"
import {
  Button,
  Grid,
  LinearProgress,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core"
import { useHistory } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import funnels from "./funnels"

const useStyle = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    flip: false,
    textAlign: "left",
    direction: "ltr",
  },
  funnelContainer: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    flip: false,
    textAlign: "left",
    direction: "ltr",
    cursor: "pointer",
  },
  textField: {
    width: 200,
  },
  bounceRates: {
    marginTop: theme.spacing(2),
  },
}))
const AnalyticsPage = () => {
  const classes = useStyle()
  const history = useHistory()
  const [fromDate, setFromDate] = useState()
  const [toDate, setToDate] = useState()
  const [webBounceRate, setWebBounceRate] = useState("")
  const [androidBounceRate, setAndroidBounceRate] = useState("")
  const [bounceRate, setBounceRate] = useState("")
  const [beingUpdated, setBeingUpdated] = useState(false)

  const loadBounceRates = () => {
    if (!fromDate) return
    const params1 = {
      platforms: ["web"],
      fromDate: fromDate,
      toDate: toDate,
    }

    const params2 = {
      platforms: ["android"],
      fromDate: fromDate,
      toDate: toDate,
    }

    const params3 = {
      platforms: ["web", "android"],
      fromDate: fromDate,
      toDate: toDate,
    }
    API.graphql(graphqlOperation(getBounceRate, params1)).then((response) => {
      setWebBounceRate(response.data.getBounceRate)
    })
    API.graphql(graphqlOperation(getBounceRate, params2)).then((response) => {
      setAndroidBounceRate(response.data.getBounceRate)
    })
    API.graphql(graphqlOperation(getBounceRate, params3)).then((response) => {
      setBounceRate(response.data.getBounceRate)
    })
  }

  const toFunnel = (id) => {
    history.push(`/funnelDetails/${id}`)
  }
  const fromDateChanged = (e) => {
    setFromDate(e.target.value)
  }

  const toDateChanged = (e) => {
    setToDate(e.target.value)
  }

  const formatRate = (rate) => {
    if (rate === "") return ""
    const result = (rate * 100).toFixed(2)
    return `${result} %`
  }

  const updateCustomersOrdersStatistics = async () => {
    setBeingUpdated(true)
    const countryCode = "de"
    const warehouseId = "4331b036-b379-4cb6-86c2-59e45f34d2e1"
    let updateExistingCustomersParams = {
      countryCode,
      warehouseId,
      customersCount: 0,
    }
    // await API.graphql(
    //   graphqlOperation(
    //     updateExistingCustomersCount,
    //     updateExistingCustomersParams
    //   )
    // )
    let allScanned = false
    let ordersParams = {
      countryCode,
      warehouseId,
      limit: 100,
    }
    while (!allScanned) {
      const response = await API.graphql(
        graphqlOperation(listOrders, ordersParams)
      )

      const orders = response.data.listOrders.items
      for (const order of orders) {
        if (
          order.orderStatus != "CANCELLED" &&
          order.shipmentType != "PICKUP" &&
          order.orderDate >= "2022-02-16"
        ) {
          const params = {
            countryCode,
            warehouseId,
            customerNumber: order.customerNumber,
            orderDate: order.orderDate,
            orderTotal: order.orderTotal,
            newCustomer: order.customerFirstOrder,
          }
          await API.graphql(graphqlOperation(addOrderToStatistics, params))
          if (order.customerFirstOrder) {
            console.log(
              "Update existing customers on order date: " + order.orderDate
            )
            updateExistingCustomersParams.customersCount = 1
            await API.graphql(
              graphqlOperation(
                updateExistingCustomersCount,
                updateExistingCustomersParams
              )
            )
            await sleep(1000)
          }
        }
      }

      const nextToken = response.data.listOrders.nextToken
      if (nextToken) {
        ordersParams.nextToken = nextToken
      } else {
        allScanned = true
      }
    }
    setBeingUpdated(false)
  }
  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  return (
    <Fragment>
      <Paper className={classes.container}>
        <Grid container spacing={2}>
          <Grid item sm={3}>
            <TextField
              id="from_date"
              label="From"
              type="date"
              className={classes.textField}
              onChange={fromDateChanged}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item sm={3}>
            <TextField
              id="to_date"
              label="To"
              type="date"
              className={classes.textField}
              onChange={toDateChanged}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item sm={3}>
            <Button
              variant="contained"
              color="primary"
              onClick={loadBounceRates}
            >
              Load
            </Button>
          </Grid>
        </Grid>
        <div className={classes.bounceRates}>
          <Typography gutterBottom>
            Web Bounce Rate : {formatRate(webBounceRate)}
          </Typography>
          <Typography gutterBottom>
            Android Bounce Rate : {formatRate(androidBounceRate)}
          </Typography>
          <Typography gutterBottom>
            Overall Bounce Rate : {formatRate(bounceRate)}
          </Typography>
        </div>
      </Paper>
      {funnels.map((funnel, index) => (
        <Paper
          className={classes.funnelContainer}
          key={index}
          onClick={(e) => toFunnel(index)}
        >
          {funnel.name}
        </Paper>
      ))}
    </Fragment>
  )
}

export default AnalyticsPage
