import React, { useEffect, useState } from "react"
import { I18n } from "aws-amplify"
import API, { graphqlOperation } from "@aws-amplify/api"
import { listPaymentWarnings } from "../../graphql/queries"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import Typography from "@material-ui/core/Typography"
import { getFormattedDateTime } from "../../utils/dateUtil"

const Order = ({ order, clickHandler }) => {
  const [paymentWarnings, setPaymentWarnings] = useState("-")
  useEffect(() => {
    if (order.paymentType === "ALADDIN_INVOICE") {
      const params = {
        orderNumber: order.orderNumber,
      }
      API.graphql(graphqlOperation(listPaymentWarnings, params)).then(
        (response) => {
          if (response.data) {
            setPaymentWarnings(response.data.listPaymentWarnings.length)
          }
        }
      )
    }
  }, [])

  const orderClicked = () => {
    clickHandler(order)
  }

  const getLocalizedOrderStatus = () => {
    const statusCode = `order_status_${order.orderStatus}`
    return I18n.get(statusCode)
  }

  const getFormattedPaymentType = () => {
    if (
      order.paymentType !== "ALADDIN_INVOICE" &&
      order.paymentType !== "ALADDIN_IN_ADVANCE" &&
      order.paymentType !== "KLARNA_INVOICE"
    )
      return order.paymentType
    if (order.paymentType === "KLARNA_INVOICE") {
      const aladdinPaymentType = `order_payment_type_${order.paymentType}`
      return I18n.get(aladdinPaymentType)
    }
    const paymentStatusCode = `order_aladdin_payment_status_${order.aladdinPaymentStatus}`
    if (order.paymentType === "ALADDIN_INVOICE")
      return `${I18n.get("order_payment_type_ALADDIN_INVOICE")} - ${I18n.get(
        paymentStatusCode
      )}`
    if (order.paymentType === "ALADDIN_IN_ADVANCE")
      return `${I18n.get("order_payment_type_ALADDIN_IN_ADVANCE")} - ${I18n.get(
        paymentStatusCode
      )}`
  }
  const getCustomerLanguage = () => {
    const languageCode = `language_${order.customerLocale}`
    return I18n.get(languageCode)
  }

  return (
    <TableRow onClick={orderClicked} style={{ cursor: "pointer" }}>
      <TableCell key="orderNumber" style={{ width: 150 }}>
        <Typography
          variant="subtitle1"
          color={
            order.orderStatus === "PLACED" && order.verifyCustomer
              ? "error"
              : "textPrimary"
          }
        >
          {order.orderNumber}
        </Typography>
      </TableCell>
      <TableCell key="orderDate" style={{ width: 100 }}>
        <Typography variant="subtitle1" color="textPrimary">
          {getFormattedDateTime(order.orderDate)}
        </Typography>
      </TableCell>
      <TableCell key="customerNumber" style={{ width: 100 }}>
        <Typography variant="subtitle1" color="textPrimary">
          {order.orderTotal}
        </Typography>
      </TableCell>
      <TableCell key="paymentType" style={{ width: 75 }}>
        <Typography variant="subtitle1" color="textPrimary">
          {getCustomerLanguage()}
        </Typography>
      </TableCell>
      <TableCell key="orderStatus" style={{ width: 50 }}>
        <Typography variant="subtitle1" color="textPrimary">
          {getLocalizedOrderStatus()}
        </Typography>
      </TableCell>

      <TableCell key="paymentType" style={{ width: 75 }}>
        <Typography variant="subtitle1" color="textPrimary">
          {getFormattedPaymentType()}
        </Typography>
      </TableCell>
      <TableCell key="paymentWarning" style={{ width: 50 }}>
        <Typography variant="subtitle1" color="textPrimary">
          {paymentWarnings}
        </Typography>
      </TableCell>
      <TableCell key="shipmentType" style={{ width: 75 }}>
        <Typography variant="subtitle1" color="textPrimary">
          {order.shipmentType}
        </Typography>
      </TableCell>
    </TableRow>
  )
}

export default Order
