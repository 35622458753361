import React, { useState } from "react"
import { API, graphqlOperation, I18n } from "aws-amplify"
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import {
  addCreditsToCustomer,
  setCustomerTrusted,
  subscribeToNotification,
  unsubscribeToNotification,
} from "../../graphql/mutations"
import { getCustomerByNumber } from "../../graphql/queries"
import Aladdin from "../../aladdin-config"
const useStyles = makeStyles((theme) => ({
  creditsContainer: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  infoWithAction: {
    marginTop: 10,
    marginBottom: 10,
  },
  actionButton: {
    marginLeft: 10,
    marginRight: 10,
  },
}))
const aladdin = Aladdin()
const CustomerInfo = (props) => {
  const classes = useStyles()
  const [customer, setCustomer] = useState(props.customer)
  const [additionalCredits, setAdditionalCredits] = useState("")
  const [newTrust, setNewTrust] = useState("")
  const [creditsDescription, setCreditsDescription] = useState("GIFT")
  const filteredCreditsDescription = aladdin.creditsDescription.filter(
    (des) =>
      des.value === "APPOLOGIZE" ||
      des.value === "COMPENSATION" ||
      des.value === "GIFT"
  )
  const creditsDescriptionOptions = filteredCreditsDescription.map((des) => {
    const label = I18n.get(des.code)
    return { label, value: des.value }
  })
  const creditsDescriptionChanged = (e) => {
    setCreditsDescription(e.target.value)
  }
  const addCreditsClicked = () => {
    if (additionalCredits > 0) {
      addCredits(additionalCredits)
      setAdditionalCredits("")
    }
  }
  const trustCustomer = async () => {
    if (!newTrust) return
    const params = {
      customerNumber: customer.customerNumber,
      trust: newTrust,
    }
    try {
      const response = await API.graphql(
        graphqlOperation(setCustomerTrusted, params)
      )
      const updated = response.data.setCustomerTrusted

      if (updated) {
        await getCustomer()
        setNewTrust("")
      }
    } catch (error) {
      console.log(error)
    }
  }
  const addCredits = async (credits) => {
    const params = {
      input: {
        customerNumber: customer.customerNumber,
        credits: credits,
        description: creditsDescription,
      },
    }
    try {
      const response = await API.graphql(
        graphqlOperation(addCreditsToCustomer, params)
      )
      if (!response.errors) {
        await getCustomer()
      }
    } catch (error) {
      console.log(error)
    }
  }
  const getCustomer = async () => {
    const params = {
      customerNumber: customer.customerNumber,
    }
    const response = await API.graphql(
      graphqlOperation(getCustomerByNumber, params)
    )
    const fetchedCustomer = response.data.getCustomerByNumber
    if (fetchedCustomer) {
      setCustomer(fetchedCustomer)
    }
  }
  const getCustomerLanguage = () => {
    let code = `language_${customer.locale}`
    return I18n.get(code)
  }
  const marketingMessageSubscriptionChanged = async () => {
    if (customer.subscribedToMarketingMessage)
      await unsubscribe("MARKETING_MESSAGE")
    else await subscribe("MARKETING_MESSAGE")
  }
  const marketingCallSubscriptionChanged = async () => {
    if (customer.subscribedToMarketingCall) await unsubscribe("MARKETING_CALL")
    else await subscribe("MARKETING_CALL")
  }
  const subscribe = async (subscriptionType) => {
    const params = {
      customerNumber: customer.customerNumber,
      notificationTypes: [subscriptionType],
    }
    try {
      const response = await API.graphql(
        graphqlOperation(subscribeToNotification, params)
      )
      if (!response.errors) {
        await getCustomer()
      }
    } catch (error) {
      console.log(error)
    }
  }
  const unsubscribe = async (subscriptionType) => {
    const params = {
      customerNumber: customer.customerNumber,
      notificationTypes: [subscriptionType],
    }
    try {
      const response = await API.graphql(
        graphqlOperation(unsubscribeToNotification, params)
      )
      if (!response.errors) {
        await getCustomer()
      }
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <React.Fragment>
      <div>
        {" "}
        {I18n.get("label_customer_number")}: {customer.customerNumber}
      </div>
      <div>
        {" "}
        {I18n.get("label_name")}: {customer.firstName} {customer.lastName}
      </div>
      <div>
        {" "}
        {I18n.get("label_username")}: <span>{customer.username}</span>
      </div>
      <div>
        {" "}
        {I18n.get("label_email")}: <span>{customer.email}</span>
      </div>
      <div>
        {" "}
        {I18n.get("label_telephone")}: <span>{customer.mobileNumber}</span>
      </div>
      <div>
        {" "}
        {I18n.get("label_language")}: <span>{getCustomerLanguage()}</span>
      </div>
      <div>
        {" "}
        {I18n.get("label_customer_trust")}: <span>{customer.trust}</span>
      </div>
      <div>
        {" "}
        {I18n.get("label_has_pending_payment")}:{" "}
        <span>
          {customer.hasPendingPayment
            ? I18n.get("label_yes")
            : I18n.get("label_no")}
        </span>
      </div>
      <div className={classes.infoWithAction}>
        {" "}
        {I18n.get("label_subscribed_marketing_message")}:{" "}
        <span>
          {customer.subscribedToMarketingMessage
            ? I18n.get("label_yes")
            : I18n.get("label_no")}
        </span>
        <Button
          variant="contained"
          color="primary"
          className={classes.actionButton}
          onClick={marketingMessageSubscriptionChanged}
        >
          {customer.subscribedToMarketingMessage
            ? I18n.get("action_deactivate")
            : I18n.get("action_activate")}
        </Button>
      </div>
      <div className={classes.infoWithAction}>
        {" "}
        {I18n.get("label_subscribed_marketing_call")}:{" "}
        <span>
          {customer.subscribedToMarketingCall
            ? I18n.get("label_yes")
            : I18n.get("label_no")}
        </span>
        <Button
          variant="contained"
          color="primary"
          className={classes.actionButton}
          onClick={marketingCallSubscriptionChanged}
        >
          {customer.subscribedToMarketingCall
            ? I18n.get("action_deactivate")
            : I18n.get("action_activate")}
        </Button>
      </div>
      <div>
        {" "}
        {I18n.get("label_customer_friendshipCode")}: {customer.friendshipCode}
      </div>
      <div>
        {" "}
        {I18n.get("label_customer_myFriendCode")}:{" "}
        {customer.myFriendCode
          ? customer.myFriendCode
          : I18n.get("label_not_exists")}
      </div>
      <div>
        {" "}
        {I18n.get("label_customer_isMyFriendCodeUsed")}:{" "}
        {customer.isMyFriendCodeUsed
          ? I18n.get("label_yes")
          : I18n.get("label_no")}
      </div>
      <Paper className={classes.creditsContainer}>
        <div>
          {" "}
          {I18n.get("label_credits")}: {customer.credits ? customer.credits : 0}
        </div>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <OutlinedInput
              style={{ height: 40 }}
              value={additionalCredits}
              onChange={(e) => setAdditionalCredits(e.target.value)}
              type="number"
            />
          </Grid>
          <Grid item xs={5}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel shrink htmlFor="des-select">
                {I18n.get("label_credits_description")}
              </InputLabel>
              <Select
                onChange={creditsDescriptionChanged}
                value={creditsDescription}
                inputProps={{
                  id: "des-select",
                }}
              >
                {creditsDescriptionOptions.map((des, index) => (
                  <MenuItem key={index} value={des.value}>
                    {des.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              color="primary"
              onClick={addCreditsClicked}
            >
              {I18n.get("action_add_credits")}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  )
}

export default CustomerInfo
/*
<div>
        <OutlinedInput
          style={{ height: 30 }}
          value={newTrust}
          onChange={(e) => setNewTrust(e.target.value)}
          type="number"
        />
        <Button
          variant="contained"
          color="primary"
          className={classes.actionButton}
          onClick={trustCustomer}
          style={{ marginBottom: "10px" }}
        >
          {I18n.get("action_trust_customer")}
        </Button>
      </div>
*/
