//TODO: This should be merged in independent component with ItemSetupDialog which creates the item
import React, { useEffect, useState } from "react"

import clsx from "clsx"

import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import InputAdornment from "@material-ui/core/InputAdornment"
import { makeStyles } from "@material-ui/core/styles"

import { I18n } from "aws-amplify"
import Aladdin from "../../aladdin-config"
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  input: {
    width: 100,
    marginBottom: theme.spacing(4),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  fullName: {
    width: 400,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

const aladdin = Aladdin()
const SetDiscountDialog = ({ item, saveHandler, open, closeHandler }) => {
  const classes = useStyles()
  const [finalPrice, setFinalPrice] = useState(item.listPrice)
  const [finalNetPrice, setFinalNetPrice] = useState(
    item.finalNetPrice ? item.finalNetPrice : item.netPrice
  )
  const [finalVATValue, setFinalVATValue] = useState("")
  const [discount, setDiscount] = useState("")
  const [endDate, setEndDate] = useState("")
  const [startDate, setStartDate] = useState("")
  const [discountType, setDiscountType] = useState("OFFER")
  const [discountLocales, setDiscountLocales] = useState({})
  const [hasError, setHasError] = useState(false)
  const [formErrors, setFormErrors] = useState([])
  const [calculateBaseOnPrice, setCalculateBaseOnPrice] = useState(false)

  const discountTypeOptions = aladdin.discountType.map((type) => {
    const label = I18n.get(type.code)
    return { label, value: type.value }
  })
  useEffect(() => {
    let temp = {}
    for (const language of aladdin.languages) {
      temp[language.locale] = language.locale === "ar"
    }
    setDiscountLocales(temp)
  }, [])
  const discountChanged = (newValue) => {
    setCalculateBaseOnPrice(false)
    if (newValue === "") {
      setDiscount("")
      setFinalNetPrice(item.netPrice)
      setFinalPrice(item.listPrice)
    } else if (newValue.match(/^[0-9]*(\.|\,)$/)) {
      //If the string ends with '.' then the next digit after '.' is to be waited
      //If the user uses comma as decimal then replace it with '.'
      newValue = newValue.replace(",", ".")
      setDiscount(newValue)
    } else if (newValue.match(/^[0-9]*(\.|\,){0,1}[0-9]{1,}$/)) {
      newValue = newValue.replace(",", ".")
      setDiscount(newValue)
      calculateNewValues(newValue, null)
    }
  }

  const finalPriceChanged = (newValue) => {
    setCalculateBaseOnPrice(true)
    if (newValue === "") {
      setDiscount("")
      setFinalNetPrice(item.netPrice)
      setFinalPrice(item.listPrice)
    } else if (newValue.match(/^[0-9]*(\.|\,)$/)) {
      //If the string ends with '.' then the next digit after '.' is to be waited
      //If the user uses comma as decimal then replace it with '.'
      newValue = newValue.replace(",", ".")
      setFinalPrice(newValue)
    } else if (newValue.match(/^[0-9]*(\.|\,){0,1}[0-9]{1,}$/)) {
      newValue = newValue.replace(",", ".")
      setFinalPrice(newValue)
      calculateNewValues(null, newValue)
    }
  }

  const calculateNewValues = (newDiscount, newFinalPrice) => {
    if (newDiscount && newDiscount !== "") {
      const discountAsFraction = newDiscount / 100
      const newFinalNetPrice = parseFloat(
        (item.netPrice * (1 - discountAsFraction)).toFixed(2)
      )
      const newFinalPrice = parseFloat(
        (newFinalNetPrice * (1 + item.VATs)).toFixed(2)
      )
      setFinalVATValue(
        parseFloat((newFinalPrice - newFinalNetPrice).toFixed(2))
      )
      setDiscount(discountAsFraction)
      setFinalNetPrice(newFinalNetPrice)
      setFinalPrice(newFinalPrice)
    } else if (newFinalPrice && newFinalPrice !== "") {
      const discountAsFraction = parseFloat(
        (1 - newFinalPrice / item.listPrice).toFixed(2)
      )
      const newFinalNetPrice = parseFloat(
        (item.netPrice * (1 - discountAsFraction)).toFixed(2)
      )
      setFinalVATValue(
        parseFloat((newFinalPrice - newFinalNetPrice).toFixed(2))
      )
      setDiscount(discountAsFraction)
      setFinalNetPrice(newFinalNetPrice)
      setFinalPrice(newFinalPrice)
    }
  }

  const typeChanged = (e) => {
    setDiscountType(e.target.value)
  }
  const handleLocaleChange = (e) => {
    setDiscountLocales({
      ...discountLocales,
      [e.target.name]: e.target.checked,
    })
  }

  const handleClose = () => {
    clear()
    closeHandler()
  }
  const handleSave = () => {
    const errors = validateInput()
    let locales = []
    for (const key of Object.keys(discountLocales)) {
      if (!!discountLocales[key]) locales.push(key)
    }
    if (errors.length > 0) {
      setHasError(true)
      setFormErrors(errors)
    } else {
      if (calculateBaseOnPrice === true) {
        saveHandler(startDate, endDate, discountType, locales, finalPrice, null)
      } else {
        saveHandler(startDate, endDate, discountType, locales, null, discount)
      }

      handleClose()
    }
  }

  const validateInput = () => {
    let errors = []
    if (discount === "") {
      errors.push(I18n.get("error_discount_required"))
    }
    let locales = []
    for (const key of Object.keys(discountLocales)) {
      if (!!discountLocales[key]) locales.push(key)
    }
    if (locales.length === 0) {
      errors.push(I18n.get("error_select_language"))
    }
    return errors
  }

  const clear = () => {
    setFinalPrice("")
    setDiscount("")
    setFinalNetPrice("")
    setStartDate("")
    setEndDate("")
    setHasError(false)
    setFormErrors([])
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        {I18n.get("label_discount_setup")}
      </DialogTitle>
      <form>
        <DialogContent>
          {hasError && (
            <Box component="div" m={1}>
              {formErrors.map((error, index) => (
                <Typography key={index} variant="body2" color="error">
                  {" "}
                  {error}
                </Typography>
              ))}
            </Box>
          )}
          <Grid container>
            <Grid item>
              <TextField
                className={classes.input}
                autoFocus={false}
                label={I18n.get("label_sku")}
                value={item.sku}
                disabled={true}
              />
            </Grid>
            <Grid item>
              <TextField
                className={clsx(classes.input, classes.fullName)}
                autoFocus={false}
                label={I18n.get("label_full_name")}
                value={item.name}
                disabled={true}
              />
            </Grid>
          </Grid>
          <FormGroup row>
            {aladdin.languages.map((language, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={discountLocales[language.locale]}
                    onChange={handleLocaleChange}
                    name={language.locale}
                  />
                }
                label={I18n.get(language.code)}
              />
            ))}
          </FormGroup>
          <Grid container>
            <Grid item>
              <FormControl className={classes.input}>
                <InputLabel shrink htmlFor="type-select">
                  {I18n.get("label_discount_type")}
                </InputLabel>
                <Select
                  onChange={typeChanged}
                  value={discountType}
                  displayEmpty
                  className={classes.selectEmpty}
                  inputProps={{
                    id: "type-select",
                  }}
                >
                  {discountTypeOptions.map((type, index) => (
                    <MenuItem key={index} value={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <TextField
                className={classes.input}
                autoFocus={false}
                label={I18n.get("label_list_price")}
                value={item.listPrice}
                disabled={true}
              />
            </Grid>
            <Grid item>
              <TextField
                className={classes.input}
                autoFocus={false}
                label={I18n.get("label_net_price")}
                value={item.netPrice}
                disabled={true}
              />
            </Grid>
            <Grid item>
              <TextField
                className={classes.input}
                autoFocus={false}
                label={I18n.get("label_final_cost")}
                value={item.cost}
                disabled={true}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item>
              <TextField
                className={classes.input}
                autoFocus={true}
                label={I18n.get("label_discount")}
                value={discount ? Math.floor(discount * 100) : ""}
                onChange={(e) => discountChanged(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                className={classes.input}
                label={I18n.get("label_final_price")}
                value={finalPrice}
                onChange={(e) => finalPriceChanged(e.target.value)}
              />
            </Grid>
            <Grid item>
              <TextField
                className={classes.input}
                label={I18n.get("label_final_net_price")}
                value={finalNetPrice}
                disabled={true}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item>
              <TextField
                label={I18n.get("label_start_date")}
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                label={I18n.get("label_end_date")}
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {I18n.get("action_cancel")}
          </Button>
          <Button onClick={handleSave} color="primary">
            {I18n.get("action_save")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default SetDiscountDialog
