import { Paper, Typography } from "@material-ui/core"
import { I18n } from "aws-amplify"
import React, { Fragment } from "react"
import { useHistory } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { Bar, Line } from "react-chartjs-2"
import { erf } from "mathjs"
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
)
const ratesOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: I18n.get("label_retention_rate"),
    },
  },
}
//xs values map orders average as follow:
//[40, 45, 50, 55, 60, 65, 70, 75, 80]
const xs = [-4, -3, -2, -1, 0, 1, 2, 3, 4]
const ordersAvg = [40, 45, 50, 55, 60, 65, 70, 75, 80]
const rates = {
  labels: ordersAvg,
  datasets: [
    {
      label: "Trust = 50",
      data: xs.map((x) => {
        return 0 * (1 + erf(x)) * 0.4
      }),
      borderColor: "rgba(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.2)",
    },
    {
      label: "Trust = 60",
      data: xs.map((x) => {
        return 1 * (1 + erf(x)) * 0.4
      }),
      borderColor: "rgba(54, 162, 235)",
      backgroundColor: "rgba(54, 162, 235, 0.2)",
    },
    {
      label: "Trust = 70",
      data: xs.map((x) => {
        return 2 * (1 + erf(x)) * 0.4
      }),
      borderColor: "rgba(255, 206, 86)",
      backgroundColor: "rgba(255, 206, 86, 0.2)",
    },
    {
      label: "Trust = 80",
      data: xs.map((x) => {
        return 3 * (1 + erf(x)) * 0.4
      }),
      borderColor: "rgba(75, 192, 192)",
      backgroundColor: "rgba(75, 192, 192, 0.2)",
    },
    {
      label: "Trust = 90",
      data: xs.map((x) => {
        return 4 * (1 + erf(x)) * 0.4
      }),
      borderColor: "rgba(153, 102, 255)",
      backgroundColor: "rgba(153, 102, 255, 0.2)",
    },
    {
      label: "Trust = 100",
      data: xs.map((x) => {
        return 5 * (1 + erf(x)) * 0.4
      }),
      borderColor: "rgba(255, 159, 64)",
      backgroundColor: "rgba(255, 159, 64, 0.2)",
    },
  ],
}
const useStyle = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    cursor: "pointer",
  },
}))

const CustomersStatisticsPage = () => {
  const classes = useStyle()
  const history = useHistory()
  const toNMonthRetentionRate = () => {
    history.push(`/nMonthRetentionRate`)
  }
  const toUnboundedRetentionRate = () => {
    history.push("/unboundedRetentionRate")
  }
  return (
    <Fragment>
      <Paper className={classes.container} onClick={toNMonthRetentionRate}>
        <Typography>{I18n.get("label_n_month_retention_rate")}</Typography>
        <Typography>{I18n.get("label_n_month_retention_rate_desc")}</Typography>
      </Paper>
      <Paper className={classes.container} onClick={toUnboundedRetentionRate}>
        <Typography>{I18n.get("label_unbounded_retention_rate")}</Typography>
        <Typography>
          {I18n.get("label_unbounded_retention_rate_desc")}
        </Typography>
      </Paper>
      <Line options={ratesOptions} data={rates} />
    </Fragment>
  )
}

export default CustomersStatisticsPage
