import React, { useContext } from "react"

import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Typography from "@material-ui/core/Typography"

import { I18n } from "aws-amplify"

import Conversation from "./Conversation"

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  tableWrapper: {
    maxHeight: 800,
    overflow: "auto",
  },
})

const ConversationsList = ({ conversations, onClick }) => {
  const classes = useStyles()

  return (
    <div className={classes.tableWrapper}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell key="createDate" style={{ width: 100 }}>
              <Typography variant="subtitle1" color="textPrimary">
                {I18n.get("label_date")}
              </Typography>
            </TableCell>
            <TableCell key="customerLocale" style={{ width: 100 }}>
              <Typography variant="subtitle1" color="textPrimary">
                {I18n.get("label_language")}
              </Typography>
            </TableCell>
            <TableCell key="source" style={{ width: 100 }}>
              <Typography variant="subtitle1" color="textPrimary">
                {I18n.get("label_conversation_source")}
              </Typography>
            </TableCell>
            <TableCell key="status" style={{ width: 50 }}>
              <Typography variant="subtitle1" color="textPrimary">
                {I18n.get("label_status")}
              </Typography>
            </TableCell>
            <TableCell key="unreadMessage" style={{ width: 50 }}>
              <Typography variant="subtitle1" color="textPrimary">
                {I18n.get("label_conversation_unread_messages")}
              </Typography>
            </TableCell>
            <TableCell key="lastMessageDate" style={{ width: 100 }}>
              <Typography variant="subtitle1" color="textPrimary">
                {I18n.get("label_conversation_last_message_date")}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {conversations.map((conversation) => (
            <Conversation
              key={conversation.createDate}
              conversation={conversation}
              onClick={onClick}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  )
}
export default ConversationsList
