import React, { useReducer, useEffect, useContext, Fragment } from "react"

import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/core/styles"

import { API, graphqlOperation } from "aws-amplify"

import GlobalContext from "../../context/global-context"
import ItemsContext from "../../context/items-context"
import itemsReducer from "../../reducers/items"
import RunOutShelvesSummary from "./RunOutShelvesSummary"
import RunOutWarehouseSummary from "./RunOutWarehouseSummary"
import ItemsSummary from "./ItemsSummary"
import { I18n } from "aws-amplify"
import { Link } from "react-router-dom"
import Typography from "@material-ui/core/Typography"

import {
  listRunOutShelves,
  listRunOutWarehouse,
  listItemsBySection,
} from "../../graphql/queries"

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    width: "40%",
    margin: theme.spacing(1),
  },
}))
const ItemsPage = () => {
  const [state, dispatch] = useReducer(itemsReducer, {
    runOutShelves: [],
    runOutWarehouse: [],
    items: [],
  })
  const { currentUser, warehouse } = useContext(GlobalContext)
  const classes = useStyles()

  useEffect(() => {
    loadRundOutShelves()
    loadRundOutWarehouse()
    loadItems()
  }, [])

  const loadRundOutShelves = async () => {
    const params = {
      warehouseId: warehouse.id,
      userLocale: currentUser.locale,
    }
    try {
      const response = await API.graphql(
        graphqlOperation(listRunOutShelves, params)
      )
      dispatch({
        type: "LOAD_RUN_OUT_SEHLVES",
        items: response.data.listRunOutShelves.items,
      })
    } catch (response) {
      console.log(response.errors)
    }
  }

  const loadRundOutWarehouse = async () => {
    const params = {
      warehouseId: warehouse.id,
      userLocale: currentUser.locale,
      threshold: 5,
    }
    try {
      const response = await API.graphql(
        graphqlOperation(listRunOutWarehouse, params)
      )
      dispatch({
        type: "LOAD_RUN_OUT_WAREHOUSE",
        items: response.data.listRunOutWarehouse.items,
      })
    } catch (response) {
      console.log(response.errors)
    }
  }

  const loadItems = async () => {
    const params = {
      warehouseId: warehouse.id,
      userLocale: currentUser.locale,
    }
    try {
      const response = await API.graphql(
        graphqlOperation(listItemsBySection, params)
      )
      dispatch({
        type: "LOAD_ITEMS",
        items: response.data.listItemsBySection.items,
      })
    } catch (response) {
      console.log(response.errors)
    }
  }
  return (
    <Fragment>
      <ItemsContext.Provider value={{ ...state, dispatch }}>
        <Paper className={classes.paper}>
          <RunOutShelvesSummary />
        </Paper>

        <Paper className={classes.paper}>
          <RunOutWarehouseSummary />
        </Paper>

        <Paper className={classes.paper}>
          <ItemsSummary />
        </Paper>
      </ItemsContext.Provider>
      <Paper className={classes.paper}>
        <Typography>{I18n.get("label_bread_orders")}</Typography>
        <Link to="/breadOrders">{I18n.get("label_details")}</Link>
      </Paper>
    </Fragment>
  )
}

export default ItemsPage
