import React, { useState, useEffect, Fragment } from "react"
import { I18n, Storage } from "aws-amplify"
import API, { graphqlOperation } from "@aws-amplify/api"
import { listPaymentWarnings } from "../../graphql/queries"
import { createPaymentWarning } from "../../graphql/mutations"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import { getFormattedShortDate } from "../../utils/dateUtil"
import { Button, LinearProgress } from "@material-ui/core"
const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    border: "solid 1px",
  },
  grid: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
}))

const PaymentWarnings = (props) => {
  const classes = useStyles()
  const [warnings, setWarnings] = useState([])
  const [count, setCount] = useState(0)
  const [beingCreatd, setBeingCreated] = useState(false)
  useEffect(() => {
    const params = {
      orderNumber: props.orderNumber,
    }
    API.graphql(graphqlOperation(listPaymentWarnings, params)).then(
      (response) => {
        if (response.data) {
          setCount(response.data.listPaymentWarnings.length)
          setWarnings(response.data.listPaymentWarnings)
        }
      }
    )
  }, [])
  const downloadBlob = (blob, filename) => {
    const url = URL.createObjectURL(blob)
    const a = document.createElement("a")
    a.href = url
    a.download = filename || "download"
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url)
        a.removeEventListener("click", clickHandler)
      }, 150)
    }
    a.addEventListener("click", clickHandler, false)
    a.click()
    return a
  }
  const downloadWarning = async (warningPath) => {
    const pathParts = warningPath.split("/")
    const result = await Storage.get(warningPath, { download: true })
    downloadBlob(result.Body, pathParts[pathParts.length - 1])
  }
  const createWarning = () => {
    if (count < 2) {
      setBeingCreated(true)
      const params = {
        orderNumber: props.orderNumber,
        countryCode: props.countryCode,
        warehouseId: props.warehouseId,
        warningNumber: count + 1,
      }
      API.graphql(graphqlOperation(createPaymentWarning, params)).then(
        (response) => {
          setBeingCreated(false)
          const warning = response.data.createPaymentWarning
          if (warning) {
            setWarnings([...warnings, warning])
            setCount(count + 1)
          }
        }
      )
    }
  }
  return (
    <div className={classes.container}>
      <div>
        <strong>{`${I18n.get("label_warnings")}: ${count}`}</strong>
      </div>
      {warnings.length < 2 && !beingCreatd && (
        <Button variant="contained" onClick={createWarning} color="primary">
          {I18n.get("action_create_warning")}
        </Button>
      )}
      {beingCreatd && <LinearProgress color="secondary" />}
      {warnings.map((warning, index) => (
        <Grid
          key={index}
          className={classes.grid}
          container
          spacing={1}
          justify="space-between"
        >
          <Grid item xs={12} lg={4}>
            {warning.warningNumber}
          </Grid>
          <Grid item xs={12} lg={4}>
            {getFormattedShortDate(warning.warningDate)}
          </Grid>
          <Grid item xs={12} lg={4}>
            <Button
              variant="contained"
              onClick={(e) => downloadWarning(warning.warningPath)}
              color="primary"
            >
              {I18n.get("action_download")}
            </Button>
          </Grid>
        </Grid>
      ))}
    </div>
  )
}

export default PaymentWarnings
