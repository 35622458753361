import React from "react"
import { Router, Switch } from "react-router-dom"
import { createBrowserHistory } from "history"

import GMRoute from "./GMRoute"
import CMRoute from "./CMRouter"
import WMRoute from "./WMRoute"
import CCRoute from "./CCRoute"
import DashboardsRoute from "./DashboardsRoute"
import DashboardsContainer from "../components/dashboards/DashboardsContainer"
import SectionsPage from "../components/section/SectionsPage"
import EmployeesPage from "../components/employees/EmployeesPage"
import CountriesPage from "../components/country/CountiesPage"
import WarehousesPage from "../components/warehouse/WarehousesPage"
import CategoriesPage from "../components/category/CategoriesPage"
import BrandsPage from "../components/brand/BrandsPage"
import ProductsPage from "../components/product/ProductsPage"
import ProductsToBeItemsPage from "../components/item-setup/ProductsToBeItemsPage"
import ProvidersPage from "../components/provider/ProvidersPage"
import ItemsPage from "../components/item/ItemsPage"
import RunOutShelves from "../components/item/RunOutShelves"
import RunOutWarehouse from "../components/item/RunOutWarehouse"
import ItemsList from "../components/item/ItemsList"
import TicketsPage from "../components/tickets/TicketsPage"
import OpenTicketPage from "../components/tickets/OpenTicketPage"
import CustomerPage from "../components/customer/CustomerPage"
import OrdersPage from "../components/order/OrdersPage"
import BannersPage from "../components/banner/BannersPage"
import OrdersSummaryPage from "../components/statistics/OrdersSummaryPage"
import ItemsSalesPage from "../components/statistics/ItemsSalesPage"
import CouponsPage from "../components/coupon/CouponsPage"
import DiscountsDetailsPage from "../components/statistics/DiscountsDetailsPage"
import AnalyticsPage from "../components/statistics/AnalyticsPage"
import FunnelDetailsPage from "../components/statistics/FunnelDetailsPage"
import CustomerSearchPage from "../components/customer/CustomerSearchPage"
import FriendCodeRemindersPage from "../components/campaign/FriendCodeRemindersPage"
import FriendCodeReminderDetails from "../components/campaign/FriendCodeReminderDetails"
import PersonalizedDiscountsCampaignsPage from "../components/campaign/PersonalizedDiscountsCampaignsPage"
import CampaignResponseDetails from "../components/campaign/CampaignResponseDetails"
import OccasionsPage from "../components/occasion/OccasionsPage"
import OccasionDetails from "../components/occasion/OccasionDetails"
import ContestOrderPage from "../components/order/ContestOrderPage"
import ConversationsPage from "../components/conversation/ConversationsPage"
import OpenConversationPage from "../components/conversation/OpenConversationPage"
import EmployeeConversations from "../components/conversation/EmployeeConversations"
import CustomersStatisticsPage from "../components/statistics/CustomersStatisticsPage"
import NMonthRetentionRate from "../components/statistics/NMonthRetentionRate"
import UnboundedRetentionRate from "../components/statistics/UnboundedRetentionRate"
import BreadOrdersPage from "../components/order/BreadOrdersPage"

export const history = createBrowserHistory()

const AppRouter = () => {
  return (
    <Router history={history}>
      <Switch>
        <DashboardsRoute exact path="/" component={DashboardsContainer} />
        <GMRoute path="/sections" component={SectionsPage} />
        <GMRoute path="/categories" component={CategoriesPage} />
        <GMRoute path="/brands" component={BrandsPage} />
        <GMRoute path="/products" component={ProductsPage} />
        <GMRoute path="/employees" component={EmployeesPage} />
        <GMRoute path="/countries" component={CountriesPage} />
        <GMRoute path="/providers" component={ProvidersPage} />
        <CMRoute path="/warehouses" component={WarehousesPage} />
        <WMRoute path="/item-setup" component={ProductsToBeItemsPage} />
        <WMRoute path="/items-page" component={ItemsPage} />
        <WMRoute path="/runOutShelves" component={RunOutShelves} />
        <WMRoute path="/runOutWarehouse" component={RunOutWarehouse} />
        <WMRoute path="/items" component={ItemsList} />
        <CCRoute path="/tickets" component={TicketsPage} />
        <CCRoute
          path="/open-ticket/:customerNumber/:ticketId/:createDate/:countryCode"
          component={OpenTicketPage}
        />
        <CCRoute path="/customer" component={CustomerSearchPage} />
        <CCRoute path="/contestOrder" component={ContestOrderPage} />
        <CCRoute
          path="/customerDetails/:customerNumber"
          component={CustomerPage}
        />
        <WMRoute path="/orders" component={OrdersPage} />
        <WMRoute path="/banners" component={BannersPage} />
        <WMRoute path="/ordersSummary" component={OrdersSummaryPage} />
        <WMRoute path="/itemsSales" component={ItemsSalesPage} />
        <WMRoute path="/coupons" component={CouponsPage} />
        <WMRoute path="/discountsDetails" component={DiscountsDetailsPage} />
        <GMRoute path="/analytics" component={AnalyticsPage} />
        <GMRoute path="/funnelDetails/:id" component={FunnelDetailsPage} />
        <WMRoute
          path="/friendCodeReminders"
          component={FriendCodeRemindersPage}
        />
        <WMRoute
          path="/friendCodeReminderDetails/:createDate"
          component={FriendCodeReminderDetails}
        />
        <WMRoute
          path="/discountsCampaigns"
          component={PersonalizedDiscountsCampaignsPage}
        />
        <WMRoute
          path="/discountsCampaignDetails/:campaignDate/:warehouseId"
          component={CampaignResponseDetails}
        />
        <GMRoute path="/occasions" component={OccasionsPage} />
        <GMRoute
          path="/occasionDetails/:warehouseId/:occasionId"
          component={OccasionDetails}
        />
        <CCRoute path="/conversations" component={ConversationsPage} />
        <CCRoute
          path="/open-conversation/:customerNumber/:countryCode/:conversationId"
          component={OpenConversationPage}
        />
        <CCRoute
          path="/employeeConversations"
          component={EmployeeConversations}
        />
        <CCRoute
          path="/customersStatistics"
          component={CustomersStatisticsPage}
        />
        <CCRoute path="/nMonthRetentionRate" component={NMonthRetentionRate} />
        <CCRoute
          path="/unboundedRetentionRate"
          component={UnboundedRetentionRate}
        />
        <WMRoute path="/breadOrders" component={BreadOrdersPage} />
      </Switch>
    </Router>
  )
}

export default AppRouter
