// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:a39a22cf-0b44-4987-9b4a-04da7707cfca",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_llZiyrNOk",
    "aws_user_pools_web_client_id": "37p67t9bhr0mmfaa08om3jnop0",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://e726rfytm5h6xndrur4ewqraby.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_dynamodb_all_tables_region": "eu-west-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "Aladdin-prod",
            "region": "eu-west-1"
        },
        {
            "tableName": "AladdinTicket-prod",
            "region": "eu-west-1"
        }
    ],
    "aws_user_files_s3_bucket": "aladdinimages124227-prod",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "aws_mobile_analytics_app_id": "4f7ff996724d4d839f306f6737295d29",
    "aws_mobile_analytics_app_region": "eu-west-1"
};


export default awsmobile;
