import React, { useState, useContext, useEffect } from "react"
import { withStyles } from "@material-ui/core/styles"
import { I18n } from "aws-amplify"
import API, { graphqlOperation } from "@aws-amplify/api"
import {
  getOrdersSummary,
  getOrderItemsDiscountsSummary,
} from "../../graphql/queries"
import {
  Paper,
  Button,
  Grid,
  CircularProgress,
  Typography,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import MuiAccordion from "@material-ui/core/Accordion"
import MuiAccordionSummary from "@material-ui/core/AccordionSummary"
import MuiAccordionDetails from "@material-ui/core/AccordionDetails"
import GlobalContext from "../../context/global-context"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"

const useStyle = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    "& div": {
      marginBottom: theme.spacing(1),
    },
  },
  accordionSummary: {
    display: "flex",
    justifyContent: "space-between",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  panelText: {
    fontSize: theme.typography.pxToRem(12),
  },

  tableContainer: {
    width: "100%",
    maxHeight: 200,
  },
}))

const OrdersSummaryPage = () => {
  const { currentUser } = useContext(GlobalContext)
  const classes = useStyle()
  const [ordersSummary, setOrdersSummary] = useState()
  const [discountsSummary, setDiscountsSummary] = useState()
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [expanded, setExpanded] = React.useState("")
  const [ordersSummaryBeingLoaded, setOrdersSummaryBeingLoaded] =
    useState(false)
  const [discountsSummaryBeingLoaded, setDiscountsSummaryBeingLoaded] =
    useState(false)
  const [discountsCosts, setDiscountsCosts] = useState(0)
  const countryCode = "de"
  const warehouseId = "4331b036-b379-4cb6-86c2-59e45f34d2e1"

  useEffect(() => {
    if (discountsSummary) {
      let costs = 0
      for (let discountSummary of discountsSummary) {
        const listPrice =
          discountSummary.avgPrice / (1 - discountSummary.avgDiscount)
        const c =
          discountSummary.quantity * (listPrice - discountSummary.avgPrice)
        costs = costs + c
      }
      setDiscountsCosts(costs)
    }
  }, [discountsSummary])

  const startDateChanged = (e) => {
    setStartDate(e.target.value)
  }
  const endDateChanged = (e) => {
    setEndDate(e.target.value)
  }

  const searchClicked = () => {
    setOrdersSummaryBeingLoaded(true)
    setDiscountsSummaryBeingLoaded(true)
    loadOrdersSummary()
    loadDiscountsSummary()
  }

  const loadOrdersSummary = async () => {
    let params = {
      countryCode,
      warehouseId,
    }
    if (startDate) {
      params.startDate = startDate
    }
    if (endDate) {
      params.endDate = endDate
    }
    console.log(params)
    const response = await API.graphql(
      graphqlOperation(getOrdersSummary, params)
    )
    setOrdersSummaryBeingLoaded(false)
    setOrdersSummary(response.data.getOrdersSummary)
  }
  const loadDiscountsSummary = async () => {
    let params = {
      countryCode,
      warehouseId,
      userLocale: currentUser.locale,
    }
    if (startDate) {
      params.startDate = startDate
    }
    if (endDate) {
      params.endDate = endDate
    }
    const response = await API.graphql(
      graphqlOperation(getOrderItemsDiscountsSummary, params)
    )
    setDiscountsSummaryBeingLoaded(false)
    const sortedDiscounts = response.data.getOrderItemsDiscountsSummary.sort(
      (a, b) => {
        if (a.quantity >= b.quantity) return -1
        return 1
      }
    )
    setDiscountsSummary(sortedDiscounts)
  }
  const formatPercentage = (value) => {
    const v = (value * 100).toFixed(0)
    return `${v} %`
  }
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const getMargin = (price, cost) => {
    const margin = price - cost

    const absMargin = parseFloat(Math.abs(margin).toFixed(2))
    return (
      <Typography
        className={classes.panelText}
        color={margin < 0 ? "error" : "secondary"}
      >
        {I18n.get("label_avg_margin")}: {absMargin}
      </Typography>
    )
  }
  return (
    <React.Fragment>
      <Grid container justify="flex-start">
        <TextField
          id="start_date"
          label={I18n.get("label_from")}
          type="date"
          className={classes.textField}
          onChange={startDateChanged}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          id="end_date"
          label={I18n.get("label_to")}
          type="date"
          className={classes.textField}
          onChange={endDateChanged}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Button
        className={classes.action}
        variant="contained"
        color="primary"
        onClick={searchClicked}
      >
        {I18n.get("action_search")}
      </Button>

      <Paper className={classes.container}>
        <Typography>{I18n.get("label_orders_summary")}</Typography>
        {ordersSummary ? (
          <React.Fragment>
            <div>
              {I18n.get("label_count")}: {ordersSummary.count}
            </div>
            <div>
              {I18n.get("label_total")}: {ordersSummary.total}
            </div>
            <div>
              {I18n.get("label_avg_total")}: {ordersSummary.avgTotal}
            </div>
            <div>
              {I18n.get("label_items_net_total")}: {ordersSummary.itemsTotal}
            </div>
            <div>
              {I18n.get("label_items_avg_net_total")}:{" "}
              {ordersSummary.avgItemsTotal}
            </div>
            <div>
              {I18n.get("label_goods_cost")}: {ordersSummary.goodsCost}
            </div>
            <div>
              {I18n.get("label_avg_goods_cost")}: {ordersSummary.avgGoodsCost}
            </div>
            <div>
              {I18n.get("label_avg_shipping_cost")}:{" "}
              {ordersSummary.avgShippingCost}
            </div>
            <div>
              {I18n.get("label_avg_payment_cost")}:{" "}
              {ordersSummary.avgPaymentCost}
            </div>
            <div>
              {I18n.get("label_gross_margin")}: {ordersSummary.grossMargin}
            </div>
            <div>
              {I18n.get("label_avg_gross_margin")}:{" "}
              {ordersSummary.avgGrossMargin}
            </div>
            <div>
              {I18n.get("label_gross_margin_rate")}:{" "}
              {formatPercentage(ordersSummary.grossMarginRate)}
            </div>
            <div>
              {I18n.get("label_net_margin")}: {ordersSummary.netMargin}
            </div>
            <div>
              {I18n.get("label_avg_net_margin")}: {ordersSummary.avgNetMargin}
            </div>
            <div>
              {I18n.get("label_net_margin_rate")}:{" "}
              {formatPercentage(ordersSummary.netMarginRate)}
            </div>
            <div>
              {I18n.get("label_profit_rate_from_total")}:{" "}
              {formatPercentage(
                ordersSummary.avgNetMargin / ordersSummary.avgTotal
              )}
            </div>
          </React.Fragment>
        ) : (
          ordersSummaryBeingLoaded && <CircularProgress color="secondary" />
        )}
      </Paper>
      <Paper className={classes.container}>
        <Typography>{I18n.get("label_discounts_summary")}</Typography>
        {discountsSummary ? (
          <React.Fragment>
            <Typography>
              {I18n.get("label_discounts_costs")} : {discountsCosts}
            </Typography>
            {discountsSummary.map((discountSummary) => (
              <Accordion
                style={{ width: "100%" }}
                key={discountSummary.sku}
                square
                expanded={expanded === discountSummary.sku}
                onChange={handleChange(discountSummary.sku)}
              >
                <AccordionSummary>
                  <Grid container justify="space-between">
                    <Typography className={classes.panelText}>
                      {discountSummary.name}
                    </Typography>
                    <Typography className={classes.panelText}>
                      {I18n.get("label_quantity")}: {discountSummary.quantity}
                    </Typography>
                    <Typography className={classes.panelText}>
                      {I18n.get("label_avg_discount")}:{" "}
                      {formatPercentage(discountSummary.avgDiscount)}
                    </Typography>
                    <Typography className={classes.panelText}>
                      {I18n.get("label_avg_price")}: {discountSummary.avgPrice}
                    </Typography>
                    <Typography className={classes.panelText}>
                      {I18n.get("label_avg_cost")}: {discountSummary.avgCost}
                    </Typography>
                    {getMargin(
                      discountSummary.avgPrice,
                      discountSummary.avgCost
                    )}
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer className={classes.tableContainer}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell>{I18n.get("label_quantity")}</TableCell>
                          <TableCell>{I18n.get("label_discount")}</TableCell>
                          <TableCell>
                            {I18n.get("label_final_net_price")}
                          </TableCell>
                          <TableCell>{I18n.get("label_final_cost")}</TableCell>
                          <TableCell>{I18n.get("label_margin")}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {discountSummary.discounts.map(
                          (discountDetails, index) => (
                            <TableRow key={index}>
                              <TableCell>{discountDetails.quantity}</TableCell>
                              <TableCell>
                                {formatPercentage(discountDetails.discount)}
                              </TableCell>
                              <TableCell>{discountDetails.price}</TableCell>
                              <TableCell>{discountDetails.cost}</TableCell>
                              <TableCell>
                                {getMargin(
                                  discountDetails.price,
                                  discountDetails.cost
                                )}
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            ))}
          </React.Fragment>
        ) : (
          discountsSummaryBeingLoaded && <CircularProgress color="secondary" />
        )}
      </Paper>
    </React.Fragment>
  )
}

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails)

export default OrdersSummaryPage
